<template>
  <div class="home" style="position: relative">
    <div class="p-5 bg-light rounded-3 d-flex align-items-center hero-image">
      <div
        style="
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: gray;
          opacity: 0.2;
          z-index: 0;
        "
      ></div>
      <div class="container py-5" style="z-index: 5">
        <div class="row">
          <div class="col-4 offset-8">
            <Tripplanner />
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="d-flex justify-content-between text-muted pt-3">
              <div>
                <svg
                  class="bd-placeholder-img flex-shrink-0 me-2 rounded"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 32x32"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#007bff"></rect>
                  <text x="50%" y="50%" fill="#007bff" dy=".3em">32x32</text>
                </svg>
                <strong class="text-gray-dark">שותף ע״י אדם</strong>
              </div>
              <div><span class="d-block">17/8/21</span></div>
            </div>
            <div>
              <h1>סופש בוינה</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container d-md-none">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <a href="" class="nav-link text-secondary">מסלול</a>
        </div>
        <div class="col d-flex justify-content-center">
          <a href="" class="nav-link text-secondary">מקומות לינה</a>
        </div>
        <div class="col d-flex justify-content-center">
          <a
            href=""
            class="nav-link text-secondary fw-bold"
            style="border-bottom: 2px solid #00ffc7"
            >שיתוף טיול</a
          >
        </div>
      </div>
    </div>
    <div class="px-4 py-5" style="background: rgba(0, 255, 199, 0.2)">
      <div class="container py-3">
        <div class="row">
          <div class="col-12 col-md-6 text-center text-md-start">
            <h1 class="fw-bold m-0">החופש שלכם ליצור את</h1>
            <h1 class="fw-bold m-0">הטיול שאתם רוצים</h1>
          </div>
          <div class="col-12 col-md-6 text-center text-md-start py-3 py-md-0">
            <h4 class="m-0">עצבו ותצרו את הטיול שתמיד רציתם</h4>
            <h4 class="m-0">דרך טיולים שכבר אחרים חוו, לא מתאים</h4>
            <h4 class="m-0">לכם פעילות מסויימת תמיד תוכלו לשנות.</h4>
          </div>
        </div>
        <div class="row">
          <div class="col text-center text-md-start">
            <h4 class="gap-3 d-flex justify-content-center">
              <span>מעוניינים להשתמש בטיול הזה?</span>
              <i class="bi-info-circle-fill"></i>
            </h4>
            <button
              type="button"
              class="
                btn btn-warning
                d-flex
                align-items-center
                mx-auto
                gap-2
                text-white
                fw-bold
              "
            >
              <span class="fs-3">בא טיול</span>
              <i class="bi-arrow-left fs-3"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 py-5">
      <div class="container py-3">
        <div class="row">
          <div class="col-12 col-md-8">
            <SingleRide v-if="allRide.ride" />
          </div>
          <div class="col-4 d-none d-md-block">
            <div class="d-flex mb-2">
              <calendar class="w-100" />
            </div>
            <SingleRideMap />
          </div>
        </div>
      </div>
    </div>

    <!-- <SingleRideHeader />
    <div class="sticky-top sticky first">
      <InnerNav />
    </div> -->

    <!-- <div id="map_canvas" style="width:700px; height:500px; margin-left:80px;"></div> -->
    <!-- <gmap-map
        :center="rideCenter"
        style="width:100%;  height: 400px;"
      >
      </gmap-map> -->

    <!-- <div class="container py-5">
      <div class="row g-0">
        <div
          class="
            col-12
            d-lg-none
            text-center
            d-md-block
            mb-5
            sticky-top sticky
            second
          "
        >
          <div v-if="isMobile()" class="shadow-sm">
            <SingleRideMap />
          </div> -->
    <!-- <button
            dir="rtl"
            v-if="allRide.ride && !compareToUserId(allRide.ride.authorId)"
            class="btn btn-warning rounded-pill px-0 py-0 mt-0 ml-auto"
            @click="addRideToUser(allRide.ride.id)"
          >
            ניתן לערוך את הטיול הזה לפי הצרכים שלכם, רוצים?
          </button> -->
    <!-- </div>
        <div class="row">
          <div class="col-12">
            <SingleRide />
          </div> -->
    <!-- <div class="col d-none d-md-block">
                <h4>פעילויות</h4>
                <p>גן חיות שונברון</p>
              </div> -->
    <!-- </div> -->
    <!-- <div class="col-12 col-lg-9">
          <SingleRide />
        </div> -->
    <!-- <div class="col-3 d-md-none d-none d-lg-block">
          <div class="sticky-top sticky second">
            <div v-if="isNotMobile()">
              <div class="card border-0 p-0 sticky second"> -->
    <!-- <SingleRideMap /> -->
    <!-- <b-btn pill variant="warning" class="px-5 py-3 mt-4"  @click="addRideToUser(allRide.ride.id)"> -->
    <!-- <button
                  dir="rtl"
                  v-if="allRide.ride && !compareToUserId(allRide.ride.authorId)"
                  class="btn btn-warning rounded-pill px-5 py-3 mt-4"
                  @click="addRideToUser(allRide.ride.id)"
                >
                  ניתן לערוך את הטיול הזה לפי הצרכים שלכם, רוצים?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import SingleRide from "@/components/SingleRide";
import SingleRideMap from "@/components/SingleRideMap";
import SingleRideHeader from "@/components/SingleRideHeader";
import InnerNav from "@/components/InnerNav";
import Tripplanner from "../components/svg/Tripplanner.vue";
import Calendar from "v-calendar/lib/components/calendar.umd";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SingleRideWithMap",
  components: {
    SingleRide,
    SingleRideMap,
    Footer,
    SingleRideHeader,
    InnerNav,
    Tripplanner,
    Calendar
  },
  computed: mapGetters(["allRide", "isAppLoggedIn", "compareToUserId"]),
  methods: {
    ...mapActions([
      "addRideToUser",
      "openLoginForm",
      "rideUpdateMarkerByScrollerLocation",
      "updateMainRideScroleLocation"
    ]),
    isMobile() {
      return window.innerWidth < 768;
    },
    // We should move it to common!!!!!!!!!!!!!!!
    isNotMobile() {
      return window.innerWidth >= 768;
    }
  },
  created() {
    window.addEventListener("scroll", this.rideUpdateMarkerByScrollerLocation);
    window.addEventListener("scroll", this.updateMainRideScroleLocation);

    const rideIdFromUrl = this.$route.params.id;
    if (rideIdFromUrl) {
      this.$store.commit("setLowedRideId", rideIdFromUrl);
    }
  },
  mounted() {
    this.$store.commit("rideSetPresentedDay", null);
  },
  destroyed() {
    window.removeEventListener(
      "scroll",
      this.rideUpdateMarkerByScrollerLocation
    );
    window.removeEventListener("scroll", this.updateMainRideScroleLocation);
  }
};
</script>

<style scoped>
.hero-image {
  position: relative;
  background-image: url("../assets/images/header.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 10em;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .hero-image {
    min-height: 16em;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .hero-image {
    min-height: 743px;
  }
}
.sidebar {
  position: fixed;
  /* right: 5px;
    top: 50px;
    width: 15%;
    height: 100vh;
    max-width: 90vw; */
  min-width: 100px;
  /* background-color: var(--accent-color); */
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.first {
  height: 70px;
  background: white;
  position: sticky;
  top: 48px;
}

.second {
  height: 50px;
  position: sticky;
  top: 118px;
}
</style>
