<template>
  <div>
    <section class="d-flex align-items-center mt-5 py-5">
      <b-container>
        <b-row>
          <b-col cols="12" class="my-3">
            <b-button 
              pill 
              variant="outline-dark"
              class="px-5 py-3"
              @click="ReturnToSingleRide()"
              >חזרה לטיול</b-button
            >
          </b-col>
          <b-col v-if="haveFormFromBaseRide" cols="12" class="my-3">
            <ins
              class="bookingaff"
              data-aid="2186348"
              data-target_aid="2186348"
              data-prod="map"
              data-width="100%"
              data-height="390"
              data-lang="he"
              data-dest_id="0"
              data-dest_type="landmark"
              :data-latitude=accommodationFormFromBaseRide.latitude
              :data-longitude=accommodationFormFromBaseRide.longtitude
              data-mwhsb="0"
              :data-checkin=accommodationFormFromBaseRideCheckinFormatedToBoking
              :data-checkout=accommodationFormFromBaseRideCheckoutFormatedToBoking
            >
              <!-- Anything inside will go away once widget is loaded. -->
              <a href="//www.booking.com?aid=2186348">Booking.com</a>
            </ins>
          </b-col>
          <b-col
            
            cols="12"
            offset="0"
            lg="5"
            offset-lg="0"
            md="8"
            offset-md="4"
            class="my-3"
          >
            <div v-if="haveFormFromBaseRide">
            <!-- form1 -->
            <div class="text-right mt-2 mb-5">
              <h4>
                מקום הלינה של מי שטיילו לפניכם 
                  <br />
                  <br />
              </h4>
            </div>
            <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
              <div>
                <b-form-input
                  disabled
                  :value="accommodationFormFromBaseRide.name"
                  class="border-0 shadow text-right"
                  size="lg"
                ></b-form-input>
              </div>
              <div class="mt-3">
                <b-form-input
                  disabled
                  :value="accommodationFormFromBaseRide.address"
                  class="border-0 shadow text-right"
                  size="lg"
                ></b-form-input>
              </div>
              <div class="mt-3">
                <b-form-input
                  disabled
                  :value="accommodationFormFromBaseRide.text"
                  class="border-0 shadow text-right"
                  size="lg"
                ></b-form-input>
              </div>
              <div class="mt-3 text-right">
                <b-link :href="accommodationFormFromBaseRide.link" target="_blank">
                  {{"accommodationFormFromBaseRide.link"}}
                </b-link>
              </div>
              <div class="mt-3 text-right">
                <h5>אלו התאריכים המתאימים לצורך תכנון הטיול שלכם</h5>
              </div>
              <div class="mt-3">
                <b-input-group class="shadow" size="lg">
                <b-form-input
                  disabled
                  :value="accommodationFormFromBaseRide.check_in_date + ' - ' + accommodationFormFromBaseRide.check_out_date"
                  class="border-0 shadow text-right"
                  size="lg"
                ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text class="py-0 border-0">
                      <b-img
                        :src="
                          require('@/assets/images/icons/icons8-edit-calendar-50.png')
                        "
                        fluid
                      ></b-img>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="mt-5">
                <b-img
                  fluid
                  :src=accommodationFormFromBaseRide.image
                  class="shadow w-100 rounded"
                ></b-img>
              </div>
            </b-form>
          </div>
          </b-col>
          <b-col
            cols="12"
            offset="0"
            md="8"
            offset-md="4"
            lg="5"
            offset-lg="2"
            class="my-3"
          >
            <!-- form2 -->
            <div class="text-right mt-2 mb-5">
              <h4>
                נא להכניס את פרטי הלינה עבור הטיול שלכם
                <br />
                <br />
              </h4>
            </div>
            <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">



              <div class="mb-3 border-0 shadow text-right">
                <b-row >
                  <b-col>
                  <h5> מילוי אוטומטי :</h5>
                  </b-col>
                  <b-col>
                  <gmap-autocomplete
                    @place_changed="handleautoCompliteFormAccommodation"
                    class="border-0 shadow text-right"
                    placeholder="שם הפעילות"
                    size=""
                  >
                  </gmap-autocomplete>
                  </b-col>
                </b-row>
              </div>

              <div>
                <b-form-input
                  :value="accommodationName"
                  @input="updateAccommodationName"
                  placeholder="שם"
                  class="border-0 shadow text-right"
                  size="lg"
                ></b-form-input>
              </div>
              <div class="mt-3">
                <b-form-input
                  :value="accommodationAddress"
                  @input="updateAccommodationAddress"
                  placeholder="כתובת"
                  class="border-0 shadow text-right"
                  size="lg"
                ></b-form-input>
              </div>
              <div class="mt-3">
                <b-form-input
                  :value="accommodationLink"
                  @input="updateAccommodationLink"
                  placeholder="קישור לאתר"
                  class="border-0 shadow text-right"
                  size="lg"
                ></b-form-input>
              </div>
              <!-- TODO this one should be and size adaptation like at ride information( vues) -->
              <div class="mt-3">
                <b-form-input
                  :value="accommodationText"
                  @input="updateAccommodationText"
                  placeholder="פרטים נוספים"
                  class="border-0 shadow text-right"
                  size="lg"
                ></b-form-input>
              </div>

              <div class="mt-3">
                <b-input-group size="lg" class="shadow">
                  <flat-pickr
                    v-model="date"
                    class="border-0 bg-white form-control datepicker text-right"
                    placeholder="צ'ק אין   ←   צ'ק אאוט"
                    :config="{ mode: 'range', dateFormat:'d-m-Y' }"
                  ></flat-pickr>

                  
                  
                  <!-- <flat-pickr
                    v-model="formDate"
                    class="border-0 bg-white form-control datepicker text-right"
                    placeholder="צ'ק אין   ←   צ'ק אאוט"
                    :config="flatPickrActive"
                  ></flat-pickr> -->
                  <b-input-group-append>
                    <b-input-group-text class="bg-white py-0 border-0">
                      <b-img
                        :src="
                          require('@/assets/images/icons/icons8-edit-calendar-50.png')
                        "
                        fluid
                      ></b-img>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="mt-3">
                <b-form-input
                  :value="accommodationBookingConfirmation"
                  @input="updateAccommodationBookingConfirmation"
                  placeholder="מספר אישור ההזמנה"
                  class="border-0 shadow text-right"
                  size="lg"
                ></b-form-input>
              </div>
              <div class="mt-3">
                <input  type="file"
                        name="file"
                        accept="image/*"
                        class="border-0 shadow text-right form-control"
                        v-on:change="uploadAccommodationImage"
                        id="file">
              </div>
              <div v-if="this.uploadValue > 0" >
              </div>
              <h4 v-if="this.isUplowding()" >{{Math.round(this.uploadValue)}}%</h4>
              <img v-if="isAccommodationImage" class="preview" height="200" width="200" :src="accommodationImage">
              <div class="mt-3 text-right">
                <h5>הוספת מיקום על המפה עבור המשתתפים האחרים שיצפו בטיול שלכם</h5>
              </div>
              <div class="mt-5">
                <GlobalMap
                  @serchPointChange="handleUpdateserchpoint">
                </GlobalMap>
              </div>

              <div class="mt-5 text-right">
                  <p v-if="accommodationErrores.length">
                    <b>בבקשה תתקנו את השדות הבאים</b>
                    <ul>
                      <li style="color: rgba(255, 0, 0, 1)" v-for="( error, index ) in accommodationErrores" :key="index"> {{error}}</li>
                    </ul>
                  </p>
              </div>

              <div v-if="haveFormFromRide" class="mt-5 text-right">
                <b-btn pill variant="warning" class="py-3 px-5 text-white" @click="this.updateTheAccommodation">עריכת הלינה </b-btn>
                <b-btn pill variant="warning" class="py-3 px-5 text-white" @click="this.deleteTheAccommodation">מחיקת הלינה </b-btn>
              </div>
              <div v-else class="mt-5 text-right">
                <!-- <a class="button is-large is-primary" @click="this.authenticate">Login</a> -->
                <b-btn pill variant="warning" class="py-3 px-5 text-white" @click="this.createAccommodation">הוספת פרטי הלינה</b-btn>
                <!-- <a class="button is-large is-success" @click="register">Register</a> -->
              </div>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <Footer />
  </div>
</template>

<script>


import Datepicker from 'vuejs-datepicker';


import GlobalMap from '@/components/GlobalMap';
import { mapActions, mapGetters } from 'vuex';
import Footer from "@/components/Footer.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import firebase from 'firebase'


export default {
  name: 'Accommodation',
  components: {
    GlobalMap,
    Footer,
    flatPickr,
    Datepicker,

  },
  data() {
    return {
      uploadValue: 0,
      value: "",
      text: "sdfsdfasdfsdfs",
      lat: 48.2220046,
      lng: 16.3738,
      flatPickrActive: {
        mode: "range",
      },
      flatPickrDisabled: {
        mode: "range",
        enableTime: true,
        defaultDate: ["2019-10-10 10:00", "2019-10-20 13:00"],
      },
    };
  },
  computed: {...mapGetters([  'allRide',
                              'email',
                              'password',
                              'accommodationName',
                              'accommodationAddress',
                              'accommodationText',
                              'accommodationBookingConfirmation',
                              'accommodationLink',
                              'accommodationLat',
                              'accommodationLng',
                              'accommodationImage',
                              'isAccommodationImage',
                              'accommodationCheckIn',
                              'accommodationCheckOut',
                              'isAccommodationEditMode',
                              'accommodationFormFromRide',
                              'haveFormFromRide',
                              'haveFormFromRideAndLocation',
                              'accommodationFormFromBaseRide',
                              'accommodationFormFromBaseRideCheckinFormatedToBoking',
                              'accommodationFormFromBaseRideCheckoutFormatedToBoking',
                              'haveFormFromBaseRide',
                              'accommodationErrores', ]),
    date:{
      get () {
        return this.accommodationCheckIn + ' to ' + this.accommodationCheckOut;

      },
      set (value) {
        this.$store.commit('setAccommodationCheckIn', value.split(' to ')[0])
        this.$store.commit('setAccommodationCheckOut', value.split(' to ')[1])
      }
    },
  },
  methods: 
  {
    ...mapActions(['register',
                  'authenticate',
                  'validateAndupdateAppToken',
                  'createAccommodation',
                  'updateTheAccommodation',
                  'deleteTheAccommodation',
                  'uploadAccommodationIfBooked',
                  'ReturnToSingleRide',
                  'setOneMarker']),
    uploadAccommodationImage(e){
      let files = e.target.files || e.dataTransfer.files;
      let imageData = files[0];
      var today=new Date();
      let myTime = today.toUTCString().split(', ')[ 1 ].split(' ').join('_')
      const storageRef = firebase.storage().ref( 'accommodation/' + this.allRide.ride.id.toString() + '_' + myTime ).put(imageData);
      
      storageRef.on(`state_changed`,snapshot=>{
      this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
          storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              const img1 = url;
              this.$store.commit('setAccommodationImage', img1)
            });
          }      
        );
    },
    isUplowding(){
      return this.uploadValue > 0
    },

    handleUpdateserchpoint (place) {
      var googleContryAndCity = ''
      if( place.address_components.filter(ac=>~ac.types.indexOf('country')).length > 0 )
        googleContryAndCity = place.address_components.filter(ac=>~ac.types.indexOf('country'))[0].long_name + ", "
      if( place.address_components.filter(ac=>~ac.types.indexOf('locality')).length > 0 ){
        googleContryAndCity += place.address_components.filter(ac=>~ac.types.indexOf('locality'))[0].long_name
      }
      this.updateAccommodationGoogleContryAndCity( googleContryAndCity );
      this.updateAccommodationLat( place.geometry.location.lat());
      this.updateAccommodationLng( place.geometry.location.lng());
      // this.updateAccommodationAddress( place.formatted_address );
    },
    handleautoCompliteFormAccommodation (place) {
      this.handleUpdateserchpoint(place)
      this.updateAccommodationName( place.name )
      this.updateAccommodationAddress( place.formatted_address )
      this.updateAccommodationLink( place.website )
      this.updateAccommodationRate( place.rating )

      if ( place.geometry ){
        this.setOneMarker( {  latPoint: place.geometry.location.lat(),
                              lngPoint: place.geometry.location.lng(),
                              name:"the new point"} )
        this.$emit("serchPointChange", place);
      }
    },

    updateAccommodationName (value) {
      this.$store.commit('setAccommodationName', value)
    },
    updateAccommodationAddress (value) {
      this.$store.commit('setAccommodationAddress', value)
    },
    updateAccommodationBookingConfirmation (value) {
      this.$store.commit('setAccommodationBookingConfirmation', value)
    },
    updateAccommodationText (value) {
      this.$store.commit('setAccommodationText', value)
    },
    updateAccommodationLink (value) {
      this.$store.commit('setAccommodationLink', value)
    },
    updateAccommodationLat (lat) {
      this.$store.commit('setAccommodationLat', lat)
    },
    updateAccommodationLng (lng) {
      this.$store.commit('setAccommodationLng', lng)
    },
    updateAccommodationImage (value) {
      this.$store.commit('setAccommodationImage', value)
    },
    updateAccommodationGoogleContryAndCity (value) {
      this.$store.commit('setAccommodationGoogleContryAndCity', value)
    },
    updateAccommodationRate ( rate ) {
      this.$store.commit('setAccommodationRate', rate )
    },
    // this is not related please remov it!!!!!!!!!!!!
    register () {
      this.$store.dispatch('register', { email: this.email, password: this.password })
        .then(() => this.$router.push('/'))
    },
  },
  created() {
    // this.validateAndupdateAppToken();
    // We should upload the accommodation infirmation if this is for editing
    this.uploadAccommodationIfBooked(); 
   },
  mounted() {
    (function (d, sc, u) {
      var s = d.createElement(sc),
        p = d.getElementsByTagName(sc)[0];
      s.type = "text/javascript";
      s.async = true;
      s.src = u + "?v=" + +new Date();
      p.parentNode.insertBefore(s, p);
    })(
      document,
      "script",
      "//aff.bstatic.com/static/affiliate_base/js/flexiproduct.js"
    );
    localStorage.setItem('workAroundToFixBookingMap_reloadePageInOrdertoCleanTheScript', 'true');
  },
};
</script>

<style>
</style>