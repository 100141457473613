<template>
  <div
    class="modal"
    tabindex="-1"
    :id="modalDialogCentered ? 'loginModal' : 'menuModal'"
  >
    <div
      class="modal-dialog"
      :class="
        modalDialogCentered ? 'modal-dialog-centered' : 'modal-fullscreen'
      "
    >
      <div class="modal-content" :class="modalDialogCentered ? '' : 'bg-black'">
        <div class="modal-header border-0" v-if="modalDialogCentered">
          <button
            type="button"
            class="btn-close m-0"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-header border-0" v-else>
          <button type="button" class="btn m-0">
            <i class="bi-list fs-2 text-white rotated"></i>
          </button>
          <button
            type="button"
            class="btn m-0"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi-x-lg text-white"></i>
          </button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modalDialogCentered: {
      type: Boolean,
      default: true
    }
    // modalFullscreen: {
    //   type: Boolean,
    //   default: false,
    // },
  }
};
</script>
<style scoped>
.rotated::before {
  transform: rotate(90deg);
}
</style>
