import { router } from '../../main';

const state = {
  modals: {
    mainLogIn: false,
    mainRegistration: false,
    signup: false,
    loginForm: false,
    signupForm: false,
    resetForm: false,
    shareRide: false,
    pleaseAddTheRideToYourList: false,
    pleaseLogInInOrderToEditRide: false,
    dynamicModal: false,
    loadingIcon: false,
  },
  dynamicModal: null,
  errorNumberConverter: {
    1: {
      text: "זה עבד",
      subText: "יופי תמשיכו",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    2: {
      text: "מצטערים",
      subText: "משהו לא עבד טוב - נטפל בזה",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    3: {
      text: "המשתמש לא רשום אצלינו במערכת",
      subText: "אתם בטוחים שנרשמתם?",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    4: {
      text: "סיסמא שגויה",
      subText: "גם לי זה קורה מידי פעם",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    5: {
      text: "אתם כבר רשומים אצלינו באתר",
      subText: "גם לי זה קורה מידי פעם",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    "rideHaveOrderedAccommodation": {
      text: "אופס, יש לך הזמנה של לינה",
      subText: "לא ניתן לערוך את הימים לאחר הכנסה של פרטי לינה, ניתן למחוק את פרטי הלינה ואז לערוך ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגור",
    },
    "fail_to_update_accommodation": {
      text: "אופס, משהוא השתבש בעדכון",
      subText: "תבדוק בבקשה שהכל עודכן כמו שצריך או צור איתנו קשר לתיקון ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגור",
    },
    "user_is_not_authorized": {
      text: "אופס, למשתמש אין הרשאה לעשות את זה",
      subText: "תתנתק בבקשה ותתחבר שוב, זה אמור לעזור ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגור",
    },
    "date_are_not_in_range": {
      text: "אופס,התאריכים לא תואמים לטיול",
      subText: "תבדדקו את זה  ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגור",
    },
    "uesr_is_not_authorize_to_edit_this_ride": {
      text: "אין לכם אפשרות לערוך את הטיול הזה",
      subText: "בחרו בכפתור ערוך את הטיול ונסו שוב",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגור",
    },
    "uesr_is_adding_new_ride": {
      text: "כל הכבוד שבחרתם לשתף",
      subText: "בואו נתחיל בהכנסת הפרטים הכללים של הטיול ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגור",
    },
    "uesr_copyed_new_ride": {
      text: "מעכשיו הטיול שלכם ואתם יכולים לערוך אותו",
      subText: "ניתן להוסיף ולהוריד פעילויות וימים ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגור",
    },

  }
};

const getters = {
  modals: state => state.modals,
  dynamicModal: state => state.dynamicModal,
  errorNumberConverter: state => state.errorNumberConverter,
};

const actions = {
  showModal({ state }, modal) {
    var modals = state.modals;
    for (const key in modals) {
      modals[key] = false;
    }
    modals[modal] = true;

  },
  showModalWithText({ state, commit }, modalInfo) {
    var modals = state.modals;
    const { errorNumberConverter } = state;
    for ( const key in modals ) {
      modals[ key ] = false;
    }
    modalInfo.errorNumber
    if( modalInfo.errorNumber in errorNumberConverter ){
      commit( 'setDynamicModal', errorNumberConverter[ modalInfo.errorNumber ] );
      modals[ errorNumberConverter[ modalInfo.errorNumber ].modalsName ] = true;
    }
    else{
      console.log( "This eror is not define !!!!!!!!!!!!!!" )
      // OPSS
      // error is not define
    }
  },
};

const mutations = {
  setModal: ( state, modal ) => {
    for (const key in state.modals) {
      state.modals[key] = false;
    }
    state.modals[modal] = true;  
  },
  setModals: ( state, modals ) => {
    state.modals = modals;
  },
  setDynamicModal: ( state, dynamicModal) => {
    state.dynamicModal = dynamicModal;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
