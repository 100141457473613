<template>
  <div class="about" style="position: relative">
    <b-container>
      <b-row>
        <b-col md="12" class="text-right my-5">
          <h4 class="font-weight-bold">אודות</h4>
        </b-col>
        <b-col cols="6" lg="6" class="d-none d-lg-block">
          <b-img
            :src="require('@/assets/images/about.png')"
            fluid
            alt="about/contact image"
          ></b-img>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          offset-lg="0"
          md="8"
          offset-md="4"
          class="text-right"
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.045em;
          "
        >
          <p>
            .
            טריפ פלנר היא פלטפורמה אשר נולדה לאחר שאשתי ואני תכננו טיול משפחתי לאוסטריה
          </p>
          <p>
              במהלך התכנון נחשפנו לכל כך הרבה מידע,
              בכל כך הרבה מקומות שונים ובצורות שונות ,
              שהחלטנו להקים מקום שיאפשר בניית טיולים בצורה נוחה וממוקדת 
          </p>
          <p>
              יש כל כך הרבה מטיילים וכל כך הרבה סגנונות.
              יש טיולים שמתאימים לך יותר ויש כאלה שפחות.
              המטרה היתה שכל אחד יוכל לקחת מה שמתאים לו מטיולים אחרים באתר ולערוך לעצמו את הטיול המושלם עבורו 
          </p>
          <p>
            אנו מקווים שהאתר יעזור לכם לשתף את הטיולים והחוויות 
            שלכם בצורה נוחה וגם שתוכלו לתכנן את הטיול הבא ולהיעזר בחוויות של אנשים שטיילו שם לפניכם
          </p>
          <p>!טיול נעים</p>
          <p>אבשלום כץ</p></b-col
        >
        <b-col cols="12" md="6" offset-md="6" class="d-flex d-lg-none">
          <b-img
            :src="require('@/assets/images/about.png')"
            fluid
            alt="about/contact image"
          ></b-img>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col md="12" class="text-right">
          <h4 class="font-weight-bold">דרכי התקשרות</h4>
        </b-col>
        <b-col
          class="text-right mt-5"
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.045em;
          "
        >
          <p>052-686-7915 <span class="turquoise">:טלפון</span></p>
          <p>avshalomkatz@gmail.com <span class="turquoise">:מייל</span></p>
          <p>Salit 4588500 <span class="turquoise">:כתובת</span></p>
          <p> </p>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Footer from "@/components/Footer.vue";

export default {
  name: "About",
  components: {
    Footer,
    
  },
  computed: mapGetters(['globalRides', 'userId' ]),
  methods: {}
}
</script>

<style>

</style>