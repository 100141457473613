let config;

// if (process.env.NODE_ENV === "production") {
//   config = {
//     $api_url: "https://api.xxx.com",
//     timeoutDuration: 30000,
//     someOtherProps: 'xyz'
//   };
// } else {
//   config = {
//     $api_url: "https://yyy.test:8443",
//     timeoutDuration: 1000,
//     someOtherProps: 'abc'
//   };
// }
config = {
        ROOT_URL: 'https://tripplanneril.herokuapp.com',
        // ROOT_URL: 'http://127.0.0.1:5000',
        // ROOT_URL: 'https://95.111.252.227',
        // $api_url: "https://api.xxx.com",
        // timeoutDuration: 30000,
        // someOtherProps: 'xyz'
        GOOGLE_API_KEY: "AIzaSyAvS-PfvIH2AHOYi4EOOaDkqm2s-2nwVbg",
        FIRE_BASE_API_KEY: "AIzaSyDVy2--48v4knREZpNqd7PAsd9TVqppbwE",
    };
 
export default { config }
