<template>
  <div class="SearchRide" style="position: relative">
    <b-container class="py-5">
      <b-row no-gutters>
        <b-col
          cols="12"
          lg="8"
          md="9"
          order="2"
          order-lg="1"
          order-md="1"
          class="mt-5 mt-lg-0 mt-md-0"
        >
            <SearchBar/>
        </b-col>
        <b-col cols="12" lg="4" md="3" order-md="2" order="1" order-lg="2">
          <h2 class="h2 text-right">הטיולים שלנו</h2>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="py-5">
      <b-row no-gutters>
        <b-col cols="12" lg="12" md="12">
          <b-row v-for="(globalRide, index ) in globalRides" :key="index" class="my-5">
            <!-- <div v-for="(globalRide, index ) in globalRides" class="border" > -->
            <b-col cols="12" class="mb-3">
            <div :id="'map_canvas_' + index" style="width:700px; height:500px; margin-left:80px;"></div>

            <h4>
              {{globalRide.title}}
            </h4>
            <h6>
              {{globalRide.authorName}}
            </h6>
            <h6>
              {{globalRide.start_date}}-{{globalRide.end_date}}
            </h6>
            <h6>
              {{globalRide.text}}
            </h6>      
            <div>
              <div v-for="(star, index) in globalRide.highLevelRideLocations">
                <h5>
                  {{star.locationName}}
                </h5>
                <h5>
                  {{star.numberOfDays}}
                </h5>
              </div>
              attractions name
              <div v-for="(attraction, index) in globalRide.attractionsName">
                <h5>
                  {{attraction}}
                </h5>
              </div>

            </div>

            <div>
              <div v-for="(star, index) in globalRide.highLevelRideLocations">
                <h5>
                  lat:{{star.lat}}, lng:{{star.lng}}
                </h5>
              </div>
            </div>

            </b-col>
            <b-col cols="12">
              <b-card no-body class="border-0">
                <b-row no-gutters>
                  <b-col cols="3" lg="4" md="4" class="d-none d-md-block">
                    <b-carousel
                      :id=carouselId(index)
                      :interval="3000"
                      controls
                      indicators
                      background="#ccc"

                    >
                      <b-carousel-slide v-for="(image, idx) in globalRide.rideImages" :key="idx">
                        <template v-slot:img>
                          <img
                            class="d-block img-fluid w-100"
                            :src=image.href
                            alt="image slot"
                            style="height: 30vh;"
                          >
                        </template>
                      </b-carousel-slide>
                    </b-carousel>
                  </b-col>
                  <b-col cols="12" lg="8" md="8">
                    <b-container>
                      <b-row class="res-row">
                        <b-col cols="12" class="mb-5 mb-md-0 mb-lg-0">
                          <b-row>
                            <b-col>
                              <div  dir="rtl" >
                                <h4 class="text-right font-weight-bold mb-3">
                                  {{ globalRide.title }}
                                </h4>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="text-right">
                              <Collapse
                                :text="globalRide.text"
                                :uniqueId="Math.random()"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="12" class="mt-5">
                          <b-row
                            no-gutters
                            class="d-flex flex-row justify-content-end"
                          >
                            <b-col>
                              <b-card no-body class="text-center border-0">
                                <b-img
                                  :src="
                                    require('@/assets/images/icons/' +
                                      'icons8-guardian-50.svg')
                                  "
                                  alt="Image"
                                  class="avatar"
                                  height="32"
                                ></b-img>
                                <p class="mt-2">{{ participent( globalRide ) }}</p>
                              </b-card>
                            </b-col>
                            <b-col>
                              <b-card no-body class="text-center border-0">
                                <b-img
                                  :src="
                                    require('@/assets/images/icons/' +
                                      'icons8-autumn-50.svg')
                                  "
                                  alt="Image"
                                  class="avatar"
                                  height="32"
                                ></b-img>
                                <p class="mt-2">{{ yearPeriod( globalRide ) }}</p>
                              </b-card>
                            </b-col>
                            <b-col>
                              <b-card no-body class="text-center border-0">
                                <b-img
                                  :src="
                                    require('@/assets/images/icons/' +
                                      'icons8-suitcase-64.svg')
                                  "
                                  alt="Image"
                                  class="avatar"
                                  height="32"
                                ></b-img>
                                <p class="mt-2">{{ rideDuration( globalRide ) }}</p>
                              </b-card>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="12" class="my-3 d-flex d-md-none">
                    <b-carousel
                      :id=carouselId(index)
                      :interval="3000"
                      controls
                      indicators
                      background="#ccc"

                    >
                      <b-carousel-slide v-for="(image, idx) in globalRide.rideImages" :key="idx">
                        <template v-slot:img>
                          <img
                            class="d-block img-fluid w-100"
                            :src=image.href
                            alt="image slot"
                            style="height: 30vh;"
                          >
                        </template>
                      </b-carousel-slide>
                    </b-carousel>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="9"
                          offset-lg="3"
                          md="12"
                          offset-md="0"
                          align-self="end"
                        >
                          <div class="d-flex">
                            <b-btn
                              @click="goToRide(globalRide.id)"
                              pill
                              variant="warning"
                              class="my-lg-0 my-md-2 my-2 ml-auto p-3"
                              >צפיה ועריכת הטיול</b-btn
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <Footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import Gallery from "vue-cover-gallery";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import Collapse from "@/components/Collapse";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "SearchRide",
  data() {
    return {
      slide: 0,
      sliding: null,
      links: [
        {
          text: "חיפוש מתקדם",
          icon: "",
        },
        {
          text: 'טיולים לחו"ל',
          icon: "",
        },
        {
          text: "טיולים בארץ",
          icon: "",
        },
        {
          text: "הטיולים הכי נפוצים",
          icon: "",
        },
        {
          text: "כל הטיולים",
          icon: "",
        },
      ],
      mobileLinks: [
        {
          text: "חיפוש מתקדם",
          icon: "",
        },
        {
          text: "הטיולים הכי נפוצים",
          icon: "",
        },
        {
          text: "כל הטיולים",
          icon: "",
        },
      ]
    };
  },
  components: {
    NavBar,
    Footer,
    Collapse,
    Carousel,
    Slide,
    SearchBar: () => import("@/components/SearchBar.vue"),

  },
  computed: mapGetters(['globalRides', 'userId' ]),
  methods: {
    ...mapActions([  'fetchGlobalRides',
                     'validateAndupdateAppToken',
                     'postFetchGlobalRides' ]),
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    curentuserIsTheRideAuthor( attractionAuthorId ){
      if( this.userId == attractionAuthorId ){
        return true;
        }
      return false
    },
    carouselId( index ){
      console.log( "carousel-" + index.toString() )
      return "carousel-" + index.toString()
    },
    yearPeriod( globalRide ){
      var dateRange
      if( globalRide.start_date.split(" ")[1] == globalRide.end_date.split(" ")[1])
        return globalRide.start_date.split(" ")[1]
      else
        return globalRide.start_date.split(" ")[1] + " - " + globalRide.end_date.split(" ")[1]
    },
    rideDuration( globalRide){
      return " טיול של " + globalRide.duration + " ימים " 
    },
    participent( globalRide ){
      var text = "" ;
      if( globalRide.number_of_child )
        text = "ילדים"
      if( ( ( globalRide.number_of_adult ) || ( globalRide.number_of_teen ) ) & ( globalRide.number_of_child ) )
        text = " ו" + text
      if( globalRide.number_of_teen )
        text = "נערים" + text
      if( ( globalRide.number_of_adult ) & ( globalRide.number_of_teen ) & ( globalRide.number_of_child ) )
        text = "," + text
      if( ( globalRide.number_of_adult ) & ( globalRide.number_of_teen ) & ( globalRide.number_of_child == 0 ) )
        text = " ו" + text
      if( globalRide.number_of_adult )
        text = "בוגרים" + text
      return text
    },
    goToRide( rideId ) {
      this.$router.push('/single_ride_with_map/'+ rideId)
    },
  },
  updated:function(){
    this.postFetchGlobalRides();
  },
};
</script>

<style>
.jumbotron {
  background: url("../assets/images/header.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 3em;
}

#container {
  width: 100%;
  height: 100%;
}
#up {
  min-height: 50px;
}
#down {
  height: calc(100% - 50px);
}
.lead-head {
  font-family: Assistant;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 39px;
  text-align: right;
}
.res-row {
  min-height: 235px;
}

@media (max-width: 575.98px) {
  .res-row {
    min-height: 235px;
  }
  .dropdown-width {
    width: 75% !important;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .res-row {
    min-height: 235px;
  }
}
@media (min-width: 1024.98px) {
  .res-row {
    min-height: 283px;
  }
}
.custom-select {
  width: 100%;
  border: 0;
  padding: 10px 10px;
  background-color: transparent;
  border-bottom: 1px solid #929fba !important;
  border-radius: 0;
}
.expand-arrow {
  background: #fff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' %3E%3Cpath d='M 44.988281 13.984375 C 44.726563 13.992188 44.476563 14.101563 44.292969 14.292969 L 25 33.585938 L 5.707031 14.292969 C 5.519531 14.097656 5.261719 13.992188 4.992188 13.988281 C 4.582031 13.992188 4.21875 14.238281 4.0625 14.613281 C 3.910156 14.992188 4 15.421875 4.292969 15.707031 L 24.292969 35.707031 C 24.683594 36.097656 25.316406 36.097656 25.707031 35.707031 L 45.707031 15.707031 C 46.003906 15.421875 46.09375 14.980469 45.9375 14.601563 C 45.777344 14.222656 45.402344 13.976563 44.988281 13.984375 Z' %3E%3C/path%3E%3C/svg%3E")
    no-repeat;
  padding: 0.4em;
}
.my-class {
  overflow: hidden;
}
</style>
