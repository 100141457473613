<template>
  <div>
    <div>
      <!-- <h2>Search and add a pin</h2> -->
      <label>
        <gmap-autocomplete
          @place_changed="updateCurrentPlace">
        </gmap-autocomplete>
        <!-- <button @click="addMarker">Add</button> -->
      </label>
      <br/>

    </div>
    <br>
    <gmap-map
      ref="mapRef"
      :center="center"
      :zoom="12"
      style="width:100%;  height: 400px;"
    >
      <!-- <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
      ></gmap-marker> -->
    </gmap-map>
  </div>
</template>



<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'GlobalMap',
  computed: mapGetters(['markers', 'center']),
  methods: 
  {
    ...mapActions([ 'geolocate',
                    'setAppMap',
                    'fetchSerchAttractionsByRide',
                    'setOneMarker']),
    updateCurrentPlace (place) {
      
      if ( place.geometry ){
        this.setOneMarker( {  latPoint: place.geometry.location.lat(),
                              lngPoint: place.geometry.location.lng(),
                              name:"the new point"} )
        this.$emit("serchPointChange", place);
      }
   },
  },
  mounted(){
    this.$refs.mapRef.$mapPromise.then((map) =>{
      this.$store.commit('setAppMap', map)

    });    
  }
};
</script>
