<template>
  <footer class="text-muted py-5 bg-eucalyptus">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3 d-flex py-3 py-md-0">
          <tripplanner-sm-icon class="mx-auto" />
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div
              class="col-md-3 col-12 py-2 d-flex"
              v-for="(footer, index) in footerLinks"
              :key="index"
            >
              <a
                :href="footer.link"
                class="nav-link mx-auto text-white"
                aria-current="page"
                >{{ footer.text }}</a
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="row my-3 my-md-0">
            <div
              class="
                col-4
                order-3 order-md-1
                d-md-block d-flex
                justify-content-center
              "
            >
              <instagram-icon />
            </div>
            <div
              class="
                col-4
                order-2 order-md-2
                d-md-block d-flex
                justify-content-center
              "
            >
              <facebook-icon />
            </div>
            <div class="col-4 p-2 order-1 order-md-3 text-center text-alto">
              <i class="bi-chevron-up fs-2"></i>
              <p class="">חזור למעלה</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mt-4 text-center text-alto">
          <h4>2021 Tripplanner - All rights reserved &copy;</h4>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import FacebookIcon from "./svg/FacebookIcon.vue";
import InstagramIcon from "./svg/InstagramIcon.vue";
import TripplannerSmIcon from "./svg/TripplannerSmIcon.vue";
export default {
  components: { TripplannerSmIcon, InstagramIcon, FacebookIcon },
  data() {
    return {
      footerLinks: [
        { text: "עמוד הבית", link: "" },
        { text: "אודות", link: "" },
        { text: "תקנון", link: "" },
        { text: "יצירת קשר", link: "" }
      ]
    };
  }
};
</script>
