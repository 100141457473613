import api from '../../api/accommodation';

import { router } from '../../main';

const state = {
  accommodations: {},
};
const getters = {
  allAccommodations: state => state.accommodations,
};
const actions = {
  async fetchAccommodations({ rootState, commit, state, dispatch }) {
    const { apptoken } = rootState.appauth;
    const rideId = rootState.ride.lowedRideId
    // We should condsider whuch value we wont to use , at the next step the other is a god option
    // const rideId = rootState.ride.rideId;
    const response = await api.fetchAccommodationsTemp(apptoken, rideId);
    commit('setAccommodations', response.data);
    // await dispatch('rideUpdateMarkerByScrollerLocation');

  },
    // var palannedDate = new Date(ride.ride.attractionsByDay[originalDayIndex].dateTime);
    // palannedDate.setHours(23,59);
    // commit('setSerchPlannedDate', palannedDate.toLocaleString("en-US"));
};

const mutations = {
  setAccommodations: (state, accommodations) => {
    state.accommodations = accommodations;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
