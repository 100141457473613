import api from '../../api/attractionUniqnes.js';

const state = {
  allAtractions: {},
  attractionsUniqnessGroupIdToMergeWith: null
};

const getters = {
  allAtractions: state => state.allAtractions,
  attractionsUniqnessGroupIdToMergeWith: state => state.attractionsUniqnessGroupIdToMergeWith,
};

const actions = {
  async fetchAttractionUniqnes({ rootState, commit, dispatch }) {
    const { apptoken } = rootState.appauth;
    dispatch( 'showModal', "loadingIcon" );
    const response = await api.fetchAttractionUniqnes(apptoken);
    dispatch( 'showModal', "" );
    commit('setAttractionUniqness', response.data);
    await dispatch('rideUpdateMarkerByScrollerLocation');

  },
  setAsMainAttraction( { rootState }, data ) {
    const { apptoken } = rootState.appauth;
    const response = api.setAsMainAttraction( apptoken, data.uniqnessAttractionId, data.attractionId );
  },
  moveAttractionToUniqnessGrop( { rootState, getters }, data ){
    const { apptoken } = rootState.appauth;
    const attractionsUniqnessGroupIdToMergeWith = getters.attractionsUniqnessGroupIdToMergeWith;
    const response = api.moveAttractionToUniqnessGrop( apptoken, data.attractionId, attractionsUniqnessGroupIdToMergeWith );
  },
};

const mutations = {
  setAttractionUniqness: (state, ride) => {
    state.allAtractions = ride.attractionsByUniqness;
  },
  setAttractionsUniqnessGroupIdToMergeWith: ( state, uniqnessGroupId ) => {
    state.attractionsUniqnessGroupIdToMergeWith = uniqnessGroupId;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
