<template>
  <div class="home" >
    <SingleRideHeader />
    <b-container class="py-5">
      <b-row  no-gutters>
        <b-col cols="12" class="d-lg-none d-md-block mb-5 sticky-top sticky" >
          <div class="shadow-sm">
            <!-- <GlobalMap
              @serchPointChange="handleUpdateserchpoint" >
            </GlobalMap> -->
          </div>
        </b-col>
        <b-col cols="12" lg="9" md="12">
          <SerchAttractions />
        </b-col>
        <b-col cols="3" class="d-md-none d-none d-lg-block">
          <!-- <b-card class="border-0 p-0 sticky-top sticky">
          </b-card> -->
            <h3>
              חיפש מקום אחר
            </h3>
            <GlobalMap
              class="border-0 p-0 sticky-top sticky"
              @serchPointChange="handleUpdateserchpoint" >
            </GlobalMap>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>

import Footer from '@/components/Footer.vue'
import SerchAttractions from '@/components/SerchAttractions';
import GlobalMap from '@/components/GlobalMap';
import SingleRideHeader from '@/components/SingleRideHeader';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SerchAtrractionWithMap',
  components: {
    SerchAttractions,
    GlobalMap,
    Footer,
    SingleRideHeader
  },
  methods: {
    ...mapActions([ 'updateMarkerByScrollerLocation',
                    'fetchSerchAttractionsByRide' ]),
    handleUpdateserchpoint( place ){
      this.$store.commit('setSerchLat', place.geometry.location.lat());
      this.$store.commit('setSerchLng', place.geometry.location.lng());
      this.$store.commit('setCurrentPlace', place)
      this.fetchSerchAttractionsByRide();
   }
  },
  created(){
    window.addEventListener('scroll', this.updateMarkerByScrollerLocation);
  },
    mounted(){
    this.$store.commit('setPresentedDay', null);
    // this.updateMarkerByScrollerLocation();
  },
  destroyed(){
    window.removeEventListener('scroll', this.updateMarkerByScrollerLocation);
  },

};


</script>

<style scoped>
  .sidebar{
    position: fixed;
    /* right: 5px;
    top: 50px;
    width: 15%;
    height: 100vh;
    max-width: 90vw; 
    min-width: 100px;
    background-color: var(--accent-color); */
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

</style>
