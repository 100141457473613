<template>
  <div>
    <div class="btn-toolbar" >
      <b-btn
        pill
        variant="warning"
        v-if="compareToUserId(allRide.ride.authorId)"
        class="mr-2 my-lg-0 my-md-2 my-2 text-white p-3"
        @click="goToNewAttraction(serchDayIndex)"
        >יצירת פעילות חדשה
      </b-btn>
      <b-btn
        pill
        variant="warning"
        class="mr-2 my-lg-0 my-md-2 my-2 text-white p-3"
        @click="ReturnToSingleRide()"
        >חזרה לטיול
      </b-btn>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="ReturnToSingleRide()" >חזרה לטיול</button>
    <h1 v-if=serchAttractios style="text-align:center">הוספת אטרקציה לטיול: {{allRide.ride.title}} </h1>
    <h1 style="text-align:center">עבור יום מספר: {{serchDayIndex+1}} </h1> -->
                <!-- <b-card no-body class="overflow-hidden my-5 border-0" v-for="(card, index) in cards" :key="index"> -->
    <div class="btn-toolbar" >
      <b-btn
        pill
        variant="warning"
        class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3"
        @click="fetchSerchAttractionsByRide( 'google' )"
        >Google</b-btn
      >
      <b-btn
        pill
        variant="warning"
        class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3"
        @click="fetchSerchAttractionsByRide( 'googleRestorant' )"
        >מסעדות</b-btn
      >
      <b-btn
        pill
        variant="warning"
        class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3"
        @click="fetchSerchAttractionsByRide( 'internal')"
        >ממסלולים של אחרים</b-btn
      >
    </div>
                <b-card
                  no-body
                  class="my-4 border-0"
                  v-for="(attraction, index ) in serchAttractios"
                  :key="index"
                >
                  <div :id="'serch_attractions_index_' + index" >
                    <b-row no-gutters>
                        <b-col cols="3" lg="4" md="4" class="d-none d-md-block">
                          <b-card-img 
                            v-if="attraction.image"
                            :src=attraction.image
                            alt="Image"
                            class="rounded"
                          ></b-card-img>
                          <span v-if="attraction.imageHtmlAttributions" v-html="getImageHtmlAttributions( attraction.imageHtmlAttributions[0])"></span>
                        </b-col>
                        <b-col cols="12" lg="8" md="8">
                          <b-container>
                            <b-row class="res-row">
                              <b-col cols="12">
                                <b-row>
                                  <b-col>
                                    <h4 class="text-right font-weight-bold mb-3">
                                      {{attraction.title}}
                                    </h4>
                                  </b-col>
                                </b-row>
                                <b-row v-if="attraction.rating">
                                  <b-col>
                                    <h6 class="text-right font-weight-bold mb-3">
                                      {{attraction.rating}}
                                    </h6>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col v-if="attraction.text">
                                    <Collapse
                                      :text="attraction.text"
                                      :uniqueId="Math.random()"
                                    />
                                  </b-col>
                                </b-row>
                              </b-col>
                              <b-col cols="12" class="my-3 d-flex d-md-none">
                                  <b-card-img
                                    v-if="attraction.image"
                                    :src=attraction.image
                                    alt="Image"
                                    class="rounded"
                                  ></b-card-img>
                                <span v-if="attraction.imageHtmlAttributions" v-html="getImageHtmlAttributions( attraction.imageHtmlAttributions[0])"></span>
                                
                              </b-col>
                              <b-col cols="12" align-self="end">
                                <div class="d-flex py-3">
                                  <b-btn
                                    pill
                                    variant="warning"
                                    class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3"
                                    @click="addAttractionToRideInternal( attraction )"
                                    >הוספת האטרקציה לטיול</b-btn
                                  >
                                </div>
                              </b-col>
                                <b-col cols="12" align-self="end">
                                  <div v-if="serchAttractiosSorce=='google'" class="d-flex py-3">
                                    <b-btn
                                      pill
                                      variant="warning"
                                      class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3"
                                      @click="viewAttraction( { 'attractionId': attraction.place_id,
                                                                'sorce':'google' } )"
                                      >פרטי האטרקציה</b-btn
                                    >
                                  </div>
                                </b-col>
                                                           
                                <b-col cols="12" align-self="end">
                                  <div v-if="serchAttractiosSorce=='internal'" class="d-flex py-3">
                                    <b-btn
                                      pill
                                      variant="warning"
                                      class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3"
                                      @click="viewAttraction( { 'attractionId': attraction.id,
                                                                'sorce':'internal' } )"
                                      >פרטי האטרקציה</b-btn
                                    >
                                  </div>
                                </b-col>
                            </b-row>
                          </b-container>
                        </b-col>
                    </b-row>
                  </div>
                  <span class="border-bottom"></span>
                </b-card>




    <!-- <div v-for="(attraction, index ) in serchAttractios" class="border" >
      <div :id="'serch_attractions_index_' + index" >
        <div class="container">
          <h3 align="right">{{attraction.title}}</h3>
          <button type="button" class="btn btn-primary" @click="addAttractionToRideInternal( attraction.id )" >הוספת האטרקציה לטיול</button>
          <div class="row">
            <div class="col-md-12">
              <img v-if="attraction.image" :src=attraction.image style='float:left;width:200px;height:200px; margin-right:10px;'>
              <div align="right">
                <p>{{attraction.text}}</p>
              </div>
            </div>
          </div>     
        </div> 
      </div>         
    </div>         -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Collapse from "@/components/Collapse";

export default {
  components: {
    Collapse,
  },

  name: 'SerchAttractions',
  computed: mapGetters([  'serchAttractios',
                          'isAppLoggedIn',
                          'allRide',
                          'serchDayIndex',
                          'plannedDate',
                          'compareToUserId',
                          'serchAttractiosSorce'
                           ]),
  methods: {...mapActions([ 'fetchSerchAttractionsByRide',
                            'addAttractionToRide',
                            'viewAttraction',
                            'validateAndupdateAppToken',
                            'ReturnToSingleRide',
                            'openLoginForm',
                            'addRideToUser',
                            'goToNewAttraction' ]),
    addAttractionToRideInternal( attraction ){
      var id;
      if ( this.serchAttractiosSorce == 'internal'){
        id = attraction.id
      }
      else if ( this.serchAttractiosSorce == 'google'){
        id = attraction.place_id
      } 
      this.addAttractionToRide( { 'attractionId': id,
                                  'sorce': this.serchAttractiosSorce } );
    },
    getImageHtmlAttributions( htmlCode ){
      return htmlCode.slice(0,3) + 'target="_blank"' + htmlCode.slice(3)
    }

  },
  created() {
    this.fetchSerchAttractionsByRide();
    this.validateAndupdateAppToken();
  }
};
</script>

<style scoped>
.ride-container {
  column-count: 3;
  column-gap: 0;
}
img {
  max-width: 100%;
  padding: 5px;
}
</style>
