<template>
  <div class="shadow-sm py-2">
    <!-- visible on lg and up -->
    <div class="container d-none d-lg-block">
      <div class="row g-0">
        <div class="col-12">
          <ul class="nav justify-content-center" v-if="allRide.ride">
            <li
              class="nav-item"
              v-for="(link, index) in links"
              @click="tubCliked(link.modal)"
              :key="index"
            >
              <a
                class="nav-link"
                aria-current="page"
                :key="index"
                :href="getUrl(link.url)"
                >{{ link.text }}</a
              >
            </li>
          </ul>
          <!-- <nav v-if="allRide.ride" class="" fill align="center">
            <b-nav-item
              exact
              active-class="active"
              class="link"
              v-for="(link, index) in links"
              @click="tubCliked(link.modal)"
              :key="index"
              :to="getUrl( link.url )"
              >{{ link.text }}
              <b-icon :icon="link.icon" variant="warning"></b-icon>
            </b-nav-item>
          </nav> -->
        </div>
      </div>
    </div>
    <!-- visible on md -->
    <div class="container fluid d-none d-lg-none d-md-block">
      <div class="row g-0">
        <div class="col-12">
          <ul class="nav justify-content-center" v-if="allRide.ride">
            <li
              class="nav-item"
              v-for="(link, index) in links"
              @click="tubCliked(link.modal)"
              :key="index"
            >
              <a
                class="nav-link"
                aria-current="page"
                :key="index"
                :href="getUrl(link.url)"
                >{{ link.text }}</a
              >
            </li>
          </ul>
          <!-- <b-nav v-if="allRide.ride" fill align="center">
            <b-nav-item
              class="link"
              v-for="(link, index) in links"
              @click="tubCliked(link.modal)"
              :key="index"
              :to="getUrl(link.url)"
              >{{ link.text }}
              <b-icon :icon="link.icon" variant="warning"></b-icon>
            </b-nav-item>
          </b-nav> -->
        </div>
      </div>
    </div>
    <!-- visible only on small screens -->
    <div class="container d-flex d-md-none">
      <div class="row g-0">
        <div class="col-12">
          <ul class="nav justify-content-center" v-if="allRide.ride">
            <li
              class="nav-item"
              v-for="(link, index) in links"
              @click="tubCliked(link.modal)"
              :key="index"
            >
              <a
                class="nav-link"
                aria-current="page"
                :key="index"
                :href="getUrl(link.url)"
                >{{ link.text }}</a
              >
            </li>
          </ul>
          <!-- <b-nav v-if="allRide.ride" fill align="center">
            <b-nav-item
              class="link"
              v-for="(link, index) in mobileLinks"
              @click="tubCliked(link.modal)"
              :to="getUrl(link.url)"
              :key="index"
              >{{ link.text }} &nbsp; &nbsp; &nbsp; &nbsp;
              <b-icon :icon="link.icon" variant="warning"></b-icon>
            </b-nav-item>
          </b-nav> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    links: {
      type: Array,
      default: () => {
        return [
          {
            text: "waze",
            icon: "",
            modal: "mavigate",
            url: "/single_ride_with_map/"
          },
          {
            text: "שיתוף הטיול",
            icon: "share",
            modal: "shareRide"
          },
          {
            text: "מקומות לינה",
            icon: "",
            url: "/ride_checklist/"
          },
          // {
          //   text: "הוספת פעילות",
          //   icon: "",
          // },
          {
            text: "האטרקציות של הטיול",
            icon: "",
            url: "/single_ride_with_map/"
          }
        ];
      }
    },
    mobileLinks: {
      type: Array,
      default: () => {
        return [
          {
            text: "שיתוף הטיול",
            icon: "icons8-connect-50.png",
            modal: "shareRide"
          },
          // {
          //   text: "הוספת פעילות",
          //   icon: "",
          // },
          {
            text: "האטרקציות של הטיול",
            icon: "",
            url: "/single_ride_with_map/"
          },
          {
            text: "waze",
            icon: "",
            modal: "mavigate",
            url: "/single_ride_with_map/"
          },
          {
            text: "מקומות לינה",
            icon: "",
            url: "/ride_checklist/"
          }
        ];
      }
    }
  },
  computed: mapGetters(["allRide", "modals"]),
  methods: {
    ...mapActions(["showModal"]),
    getUrl(url) {
      if (url) return url + this.allRide.ride.id.toString();
    },
    tubCliked(modal) {
      if (modal == "") {
        return;
      }
      this.showModal(modal);
      this.$store.commit("setNevigateMode", modal == "mavigate");
    }
  }
};
</script>
<style>
.nav-link {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #0d2f29;
}
.nav-link:focus,
.nav-link:hover {
  color: #34d0b6;
}
.active {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #34d0b6;
}
</style>