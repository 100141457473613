import Vuex from 'vuex';
import Vue from 'vue';
import auth from './modules/auth';
import images from './modules/images';
import ride from './modules/ride';
import attractionUniqnes from './modules/attractionUniqnes';
import accommodations from './modules/accommodations';
import serchAttractions from './modules/serchAttractions';
import serchAttractionsWithMap from './modules/serchAttractionsWithMap';
import appauth from './modules/appauth';
import singleMapRide from './modules/singleMapRide';
import globalMap from './modules/globalMap';
import commonMap from './modules/commonMap';
import createAttraction from './modules/createAttraction';
import accommodation from './modules/accommodation';
import userRides from './modules/userRides';
import rideInformation from './modules/rideInformation';
import globalRides from './modules/globalRides';
import popups from './modules/popups';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    images,
    ride,
    attractionUniqnes,
    appauth,
    singleMapRide,
    serchAttractions,
    serchAttractionsWithMap,
    globalMap,
    commonMap,
    createAttraction,
    accommodation,
    accommodations,
    userRides,
    rideInformation,
    globalRides,
    popups,
  }
});
