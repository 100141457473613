<template>
  <div>
    
    <section
      class="d-flex align-items-center"
      style="min-height: calc(100vh - 150px)"
    >   
      <b-container class="mt-5">
        <b-row>
          <b-col cols="12">
            <b-card no-body class="border-0 shadow py-3">
              <h4 class="font-weight-bold text-center">פרטי הטיול</h4>
              <div  dir="rtl" >

              <b-form
                role="form"
                @submit.prevent="handleSubmit(onSubmit)"
                class="row mt-4"
              >
                <b-col cols="11" lg="8" md="8" class="mx-auto">
                  <div class="my-3">
                    <b-form-input
                      id="title"
                      :value="rideInformationTitle"
                      @input="updateTitle"
                      placeholder="כותרת הטיול"
                      class="border-0 shadow text-right"
                      size="lg"
                    ></b-form-input>
                  </div>
                  <div class="my-3">
                    <textarea-autosize
                      id="text"
                      v-model="localRideInformationText"
                      class="border-0 shadow text-right form-control"
                      placeholder="טקסט חופשי על הטיול"
                      ref="myTextarea"
                      :min-height="151"
                    />
                  </div>
                  <!-- <div class="my-3">
                    <p class="m-0 text-right turquoise">דעי ףסוה +</p>
                  </div> -->





<!-- I should add the:
  3. add the ability to edit the ride information and send only the start date( server should be fine)
  4. input verification befor sending the form -->






                  <div class="my-3">
                    <b-row no-gutters class="shadow">
                      <b-col>
                        <b-dropdown
                          variant="dark-oultine"
                          class="form-control bg-transparent rounded-0 border-0"
                          size="lg"
                          right
                          block
                          no-caret
                        >
                          <template #button-content>
                            <div class="d-flex align-items-center">
                              <span class="expand-arrow mr-2"></span>
                              <small class="ml-auto small"
                                >הרכב גילאי המשתתפים</small
                              >
                            </div>
                          </template>
                          <b-dropdown-form
                            style="width: 280px; text-align: right"
                          >
                            <b-row class="align-items-center mb-2">
                              <b-col>
                                <b-form-spinbutton
                                  v-model="numbrOfAdoult"
                                  min="0"
                                ></b-form-spinbutton>
                              </b-col>
                              <b-col>בוגרים</b-col>
                            </b-row>
                            <b-row class="align-items-center mb-2">
                              <b-col>
                                <b-form-spinbutton
                                  v-model="numbrOfTeen"
                                  min="0"
                                ></b-form-spinbutton>
                              </b-col>
                              <b-col>נערים</b-col>
                            </b-row>
                            <b-row class="align-items-center">
                              <b-col>
                                <b-form-spinbutton
                                  v-model="numbrOfChild"
                                  min="0"
                                ></b-form-spinbutton>
                              </b-col>
                              <b-col>ילדים</b-col>
                            </b-row>
                          </b-dropdown-form>
                        </b-dropdown>
                      </b-col>
                      <b-col>
                        <b-input-group size="lg">
                          <flat-pickr v-if="isRideInformationEditMode"
                            v-model="date"
                            class="border-0 bg-white form-control datepicker text-right"
                            placeholder="תאריך התחלה של הטיול"
                            :config="{ dateFormat:'d-m-Y' }"
                          ></flat-pickr>
                          <flat-pickr v-else
                            v-model="date"
                            class="border-0 bg-white form-control datepicker text-right"
                            placeholder="תאריך התחלה וסיום של הטיול"
                            :config="{ mode: 'range', dateFormat:'d-m-Y' }"
                          ></flat-pickr>
                          <b-input-group-append>
                            <b-input-group-text class="bg-white py-0 border-0">
                              <b-img
                                :src="
                                  require('@/assets/images/icons/icons8-edit-calendar-50.png')
                                "
                                fluid
                              ></b-img>
                            </b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </div>





                  <div class="text-center my-3">
                    <b-row class="justify-content-center">
                      <b-col lg="6" md="6">
                        <div class="mt-3 text-right rtl">
                          <b-form-checkbox
                            v-model="physicalDisabilities"
                            style="display: inline-block"
                            id="remember-me"
                            class="mb-2 mr-sm-2 mb-sm-0"
                          ></b-form-checkbox>
                          <label for="remember-me" class="mr-3">
                            מתאים גם לבעלי מוגבלויות
                          </label>
                        </div>
                        <div v-if="isRideInformationEditMode">
                          <b-btn
                            @click="this.updateRideInformation"
                            pill
                            block
                            variant="warning"
                            class="py-2 text-white">
                            שמירת השינויים
                          </b-btn>
                          <b-btn
                          pill
                          block
                          variant="warning"
                          class="py-2 text-white"
                          @click="ReturnToSingleRide()"
                          >חזרה לטיול</b-btn>
    
                        
                        </div>
                        <div v-else >
                          <b-btn
                            @click="this.createRideInformation"
                            pill
                            block
                            variant="warning"
                            class="py-2 text-white">
                            יצירת הטיול
                          </b-btn>
                        </div>
                        <br>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>

              </b-form>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <Footer />
  </div>
</template>


<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from 'vuex';

export default {
data() {
  return {
    value: "",
    value1: 2,
    value2: 2,
    value3: 0,
    text: "",
    // date: null,
  };
  },
  name: 'RideInformation',
    components: {
      NavBar,
      Footer,
      flatPickr,
    },
    computed: {...mapGetters([  'rideInformationTitle',
                                'rideInformationText',
                                'rideInformationNumbrOfAdoult',
                                'rideInformationNumbrOfTeen',
                                'rideInformationNumbrOfChild',
                                'rideInformationPhysicalDisabilities',
                                'rideInformationCheckIn',
                                'rideInformationCheckOut',
                                'isRideInformationEditMode' ]),
      localRideInformationText:{
        get () {
          return this.rideInformationText
        },
        set (value) {
          this.$store.commit('setRideInformationText', value )
        }
      },
      date:{
        get () {
          return this.rideInformationCheckIn

        },
        set (value) {
          this.$store.commit('setRideInformationCheckIn', value.split(' to ')[0])
          this.$store.commit('setRideInformationCheckOut', value.split(' to ')[1])

        },
      },
      physicalDisabilities:{
        get () {
          return this.rideInformationPhysicalDisabilities
        },
        set (value) {
          this.$store.commit('setRideRideInformationPhysicalDisabilities', value)
        }
      },
      numbrOfAdoult:{
        get () {
          return this.rideInformationNumbrOfAdoult
        },
        set (value) {
          this.$store.commit('setRiderideInformationNumbrOfAdoult', value)
        }
      },
      numbrOfTeen:{
        get () {
          return this.rideInformationNumbrOfTeen
        },
        set (value) {
          this.$store.commit('setRideRideInformationNumbrOfTeen', value)
        }
      },
      numbrOfChild:{
        get () {
          return this.rideInformationNumbrOfChild
        },
        set (value) {
          this.$store.commit('setRideRideInformationNumbrOfChild', value)
        }
      },
    },
    methods: 
    {
    ...mapActions([ 'register',
                    'authenticate',
                    'validateAndupdateAppToken',
                    'createRideInformation',
                    'updateRideInformation',
                    'ReturnToSingleRide', 
                    ] ),
    updateTitle ( value ) {
      this.$store.commit('setRideInformationTitle', value )
    },
    updateNumbrOfAdoult (e) {
      this.$store.commit('setRiderideInformationNumbrOfAdoult', e.target.value)
    },
    updateNumbrOfTeen (e) {
      this.$store.commit('setRideRideInformationNumbrOfTeen', e.target.value)
    },
    updateNumbrOfChild (e) {
      this.$store.commit('setRideRideInformationNumbrOfChild', e.target.value)
    },
    updatePhysicalDisabilities (e) {
      this.$store.commit('setRideRideInformationPhysicalDisabilities', e.target.value)
    },
    register () {
      this.$store.dispatch('register', { email: this.email, password: this.password })
        .then(() => this.$router.push('/'))
    }
  },
};
</script>












<style>
.expand-arrow {
  background: #fff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' %3E%3Cpath d='M 44.988281 13.984375 C 44.726563 13.992188 44.476563 14.101563 44.292969 14.292969 L 25 33.585938 L 5.707031 14.292969 C 5.519531 14.097656 5.261719 13.992188 4.992188 13.988281 C 4.582031 13.992188 4.21875 14.238281 4.0625 14.613281 C 3.910156 14.992188 4 15.421875 4.292969 15.707031 L 24.292969 35.707031 C 24.683594 36.097656 25.316406 36.097656 25.707031 35.707031 L 45.707031 15.707031 C 46.003906 15.421875 46.09375 14.980469 45.9375 14.601563 C 45.777344 14.222656 45.402344 13.976563 44.988281 13.984375 Z' %3E%3C/path%3E%3C/svg%3E")
    no-repeat;
  padding: 0.4em;
}
</style>