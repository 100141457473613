<template>
  <div>
    <b-container class="py-5">
      <b-row>
        <b-col cols="12" md="8" offset-md="4" class="pt-5">
          <div class="d-flex justify-content-between align-items-center">
            <b-btn @click="openRideInformationPage()" pill variant="warning" class="px-5 py-3 text-white"
              >הכנסת פרטי טיול בשביל לשתף משתמשים אחרים</b-btn
            >
            <h4 class="font-weight-bold mr-md-4">הטיולים שלי</h4>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="py-5">
      <b-row>
        <b-col>
          <b-card
            no-body
            class="mb-5 border-0"
            v-for="(userRide, index) in userRides"
            :key="index"
          >
            <!-- visible md and up -->
            <!-- <h1>{{userRide}}</h1> -->
            <div class="d-none d-md-flex">
              <b-row class="align-items-center w-100">
                <!-- <b-col cols="auto">
                  <b-card-img
                    :src="require('@/assets/images/' + cards[0].cardImg)"
                    width="36"
                    class="rounded"
                  ></b-card-img>
                </b-col> -->
                <b-col>
                  <div>
                    <b-row>
                      <b-col class="text-right py-3">
                        <div  dir="rtl" >
                          <span style="white-space: pre-wrap;"><h4>{{ userRide.title }}</h4></span>
                          <span style="white-space: pre-wrap;"><p>{{ userRide.text }}</p></span>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right py-3">
                        <p class="d-inline mr-4">{{ userRide.start_date }} - {{ userRide.end_date }}</p>
                        <img
                          :src="require('@/assets/images/icons/icons8-edit-calendar-50.png')"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right py-3">
                        <b-btn
                          @click="goToRide(userRide.id)"
                          pill
                          variant="warning"
                          class="px-5 py-3 text-white"
                          >צפיה ועריכה של טיול זה</b-btn
                        >
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- visible only on small screens -->
            <!-- <div class="d-flex d-md-none text-center pt-5"> -->
            <div class="d-flex d-md-none">
              <div>
                <b-row>
                  <b-col class="text-right py-3">
                    <h4>{{ userRide.title }}</h4>
                    <p>{{ userRide.text }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right py-3">
                    <p class="d-inline mr-4">{{ userRide.start_date }} - {{ userRide.end_date }}</p>
                    <img :src="require('@/assets/images/icons/icons8-edit-calendar-50.png')" />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- <b-col>
                    <b-card-img
                      :src="require('@/assets/images/' + cards[0].cardImg)"
                      class="rounded"
                      height="150"
                    ></b-card-img>
                  </b-col> -->
                </b-row>
                <b-row>
                  <b-col class="text-right py-3">
                    <b-btn
                      @click="goToRide(userRide.id)"
                      pill
                      variant="warning"
                      class="px-5 py-3 text-white"
                      >צפיה ועריכה של טיול זה</b-btn
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <Footer />
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: 'UserRidesV',
  computed: mapGetters(['userRides', 'isAppLoggedIn', 'userId' ]),

  data() {
    return {
      cards: [
        {
          header: "2,4,6,8 : יאליגב םידלי 4 םע םוי 14 הירטסוא",
          subheader: "הינמרגב רצק רוקיבו הירטסואב םיהדמ לויט",
          icon: "icons8-edit-calendar-50.png",
          date: "30.11.20 דע 24.11.202020 ךיראתמ",
          buttonText: "הזה לויט ךורעו הפצ",
          cardImg: "251.png",
        },
        {
          header: "טקרמנטלאב רמיצל העיסנ",
          subheader:
            "םירוהה לע סמוע ךסחנ ךכמו םידליה לע סמוע רוציל אל יאדכ קלחל םיצילממ ונא תוכוראה תועיסנה לכ תא (תועש 4) טקרמנטלא רמיצל העיסנ",
          icon: "icons8-edit-calendar-50.png",
          date: "30.11.20 דע 24.11.202020 ךיראתמ",
          buttonText: "הזה לויט ךורעו הפצ",
          cardImg: "151.png",
        },
      ],
    };
  },
  components: {
    NavBar,
    Footer,
  },
    methods: { ...mapActions([  'fetchUserRides',
                                'serchAttractionToRide',
                                'removeAttractionFromRide',
                                'validateAndupdateAppToken',
                                'openRideInformationPage' ]),
    curentuserIsTheRideAuthor( attractionAuthorId ){
      if( this.userId == attractionAuthorId ){
        return true;
        }
      return false
    },
    goToRide( rideId ) {
      this.$router.push('/single_ride_with_map/' + rideId )
    }
  },
  created() {
    this.validateAndupdateAppToken();
    this.fetchUserRides();
  },
};
</script>