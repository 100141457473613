import serchApi from '../../api/serchAttractions';
import editApi from '../../api/editRide';
import { router } from '../../main';

const state = {
  serchAttractios: {},
  lat: +48.18,
  lng: +16.30,
  plannedDate: null,
  serchDayIndex: null,
  sorce: 'internal',
  // rideId: null
};

const getters = {
  serchAttractios: state => state.serchAttractios,
  lat: state => state.lat,
  lng: state => state.lng,
  plannedDate: state => state.plannedDate,
  serchDayIndex: state => state.serchDayIndex,
  serchAttractiosSorce: state => state.sorce,
  // rideId: state => state.rideId
};

const actions = {
  async fetchSerchAttractionsByRide({ state, rootState, commit, dispatch }, sorce='internal') {
    const rideId = rootState.ride.rideId;
    // we should add the option to serch attraction when the day is empty from
    // attraction ( we should use the globak ride location or the last point that have activity)
    const lat = state.lat; 
    const lng = state.lng;
    const { apptoken } = rootState.appauth;
    var response;
    if( sorce == 'internal' ){
      response = await serchApi.fetchSerchAttractionsByRide(apptoken, rideId, lat, lng);
      commit('setSerchSorce', 'internal');
    }
    else if( ( sorce == 'google' ) || ( sorce == 'googleRestorant' ) ){
      var serchType;
      if( sorce == 'google'){
        serchType = 'googleAttractions';
      }
      else{
        serchType = sorce;
      }
      response = await serchApi.fetchSerchActivityByRideFromGoogle(apptoken, rideId, lat, lng, serchType);
      commit('setSerchSorce', 'google');
    }
    // else if( sorce == 'googleRestorant' ){
    //   response = await serchApi.fetchSerchRestornatsByRideFromGoogle(apptoken, rideId, lat, lng);
    //   // the input sorece is google and not googleRestorant
    //   commit('setSerchSorce', 'google');
    // }
      commit('setSerchAttractions', response.data);
    await dispatch('updateMarkerByScrollerLocation');
    //router.push('/serch_attractions');
  },
  
  // we should move this method to _____!
  async addAttractionToRide( {dispatch, getters, state, rootState }, attractionInfo ){
    const { apptoken } = rootState.appauth;
    const authorId = getters.allRide.ride.authorId;
    const userIsTheRideHoner = await dispatch( 'equalToUserId', authorId );
    if( apptoken ){
      if( userIsTheRideHoner ){
        const rideId = rootState.ride.rideId;
        const plannedDate = state.plannedDate
        // print( attractionInfo['attractionId'] )
        // print( attractionInfo['sorce'] )
        if ( attractionInfo['sorce'] == "internal" ){
          await editApi.addAttractionToRide(  apptoken,
                                              rideId,
                                              attractionInfo['attractionId'],
                                              plannedDate );
          }
        else if ( attractionInfo['sorce'] == "google" ){
          await editApi.addgooglePlaceToRide( apptoken,
                                              rideId,
                                              attractionInfo['attractionId'],
                                              plannedDate );
        }
        router.push('/single_ride_with_map/' + rideId );
      }
      else{
        dispatch( 'showModal', "pleaseAddTheRideToYourList" );
      }
    }
    else{
      dispatch( 'showModal', "pleaseLogInInOrderToEditRide" );
    }
  },
  
};

const mutations = {
  setSerchAttractions: (state, serchAttractios) => {
    state.serchAttractios = serchAttractios;
  },
  setSerchLat: (state, lat) => {
    state.lat = lat;
  },
  setSerchLng: (state, lng) => {
    state.lng = lng;
  },
  setSerchPlannedDate: (state, plannedDate) => {
    state.plannedDate = plannedDate;
  },
  setSerchDayIndex: (state, serchDayIndex) => {
    state.serchDayIndex = serchDayIndex;
  },
  setSerchSorce: ( state, sorce) =>{
    state.sorce = sorce;
  }
  
};

export default {
  state,
  getters,
  actions,
  mutations
};
