import axios from 'axios';

import config from '../common/config';

export default {
  getAttraction( attractionId, sorce ) {
    return axios.get(`${config.config.ROOT_URL}/attraction_api/${attractionId}/${sorce}`);
  },
  updateAttraction( token, attractionId, attractionInfo)
    {
      const formData = new FormData()
      formData.append('data', JSON.stringify(attractionInfo));

      axios.post(`${config.config.ROOT_URL}/update_attraction_api/${attractionId}`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    }
};
