<template>
    <b-modal
      id="mainLogIn"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
      centered
    >

  <!-- <b-modal hide-footer centered v-model="isVisible"> -->
    <section class="text-center">
      <b-row no-gutters class="justify-content-center">
        <b-col cols="12" class="py-2">
          <h3 class="font-weight-bold">התחברות</h3>
          <b-link @click="goToTermsOfUse">
            <small>
            על ידי המשך התהליך
            <u>אני מסכים לתנאי </u> 
            <u>השימוש ולמדיניות הפרטיות של האתר </u>
          </small>
          </b-link>
        </b-col>
        <b-col cols="8" class="py-2">
          <b-btn 
            pill 
            variant="outline-info"
            block class="py-2"
            @click="loginToAppWithGoogle"
            >
            Google <span class="text-dark">התחברות באמצעות</span></b-btn
          >
        </b-col>
        <!-- <b-col cols="8" class="py-2">
          <b-btn pill variant="outline-info" block class="py-2">
            Facebook <span class="text-dark">התחברות באמצעות</span></b-btn
          >
        </b-col> -->
        <b-col cols="8" class="py-2"> ֹאו </b-col>
        <b-col cols="8" class="py-2">
                      <div>
                <!-- v-model="text" -->
              <b-form-input
                :value="email"
                @input="updateEmail"
                id="email"
                placeholder="מייל"
                class="border-0 shadow text-right"
                required
              ></b-form-input>
            </div>
            <div class="mt-3">
              <b-input-group class="mt-3 shadow">
                <b-input-group-prepend>
                  <b-button
                    @click.prevent="$emit('showmodal', 'resetForm')"
                  >
                    ?לא זוכרים
                  </b-button>
                </b-input-group-prepend>
                  <!-- v-model="text" -->
                <b-form-input
                  @input="updatePassword"
                  placeholder="סיסמא"
                  class="border-0 text-right"
                  type="password"
                ></b-form-input>
              </b-input-group>
            </div>
          <p></p>
          <b-btn
            pill
            variant="warning"
            block
            class="py-2"
            @click="loginToApp"
          >
            התחברות באמצעות המייל</b-btn
          >
        </b-col>
        <b-col cols="8" class="py-2">
          <b-link @click="goToNewUserForm">
            אין לכם חשבון?
            הרשמה
          </b-link>
        </b-col>
      </b-row>
    </section>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ["value"],
  computed: {
     ...mapGetters([  'email',
                      'password',
                      'rePassword',
                      'userName',
                      'isNewUser']),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: 
  {
    ...mapActions(['register',
                   'authenticate',
                   'registerNewUser',
                   'validateAndupdateAppToken',
                   'closeLoginForm',
                   'goToTermsOfUse',
                   'loginToAppWithGoogle',
                   ]),
    goToNewUserForm(){
      this.$store.dispatch( 'showModal', "mainRegistration" );    
    },
    loginToApp ( ) {
      // event.preventDefault();
      this.$store.dispatch( 'showModal', "" );
      // this.$store.dispatch('closeLoginForm');
      this.$store.dispatch('authenticate');
    },
    updateEmail (value) {
      this.$store.commit('setEmail', value)
    },
    updateUserName (value) {
      this.$store.commit('setUserName', value)
    },
    updatePassword (value) {
      this.$store.commit('setPassword', value)
    },
    updateRePassword (value) {
      this.$store.commit('setRePassword', value)
    },
  },

};
</script>
<style>
</style>