<template>
  <div>
    <!-- signup modal -->
    <Signup v-model="modals.signup" @showmodal="showModal($event)" />
    <!-- login form -->
    <LoginForm v-model="modals.loginForm" @showmodal="showModal($event)" />
    <!-- signup Form -->
    <SignupForm v-model="modals.signupForm" @showmodal="showModal($event)" />
    <!-- reset form -->
    <ResetForm v-model="modals.resetForm" @showmodal="showModal($event)" />

    <MainLogIn v-model="modals.mainLogIn" />
    <MainRegistration v-model="modals.mainRegistration" />
    <ShareRide v-if="allRide.ride" v-model="modals.shareRide" />
    <PleaseAddTheRideToYourList v-model="modals.pleaseAddTheRideToYourList" />
    <PleaseLogInInOrderToEditRide v-model="modals.pleaseLogInInOrderToEditRide" />
    <DynamicModal v-model="modals.dynamicModal" />
    <LoadingIcon v-model="modals.loadingIcon" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import Login from "@/components/Modals/Login";
import Signup from "@/components/Modals/Signup";
import LoginForm from "@/components/Modals/LoginForm";
import SignupForm from "@/components/Modals/SignupForm";
import ResetForm from "@/components/Modals/ResetForm";
import ShareRide from "@/components/Modals/ShareRide";
import LoadingIcon from "@/components/Modals/LoadingIcon";
import MainLogIn from "@/components/Modals/MainLogIn";
import MainRegistration from "@/components/Modals/MainRegistration";
import PleaseAddTheRideToYourList from "@/components/Modals/PleaseAddTheRideToYourList";
import PleaseLogInInOrderToEditRide from "@/components/Modals/PleaseLogInInOrderToEditRide";
import DynamicModal from "@/components/Modals/DynamicModal";

export default {
  name: "Popups",
  computed: mapGetters(["modals", "allRide"]),
  methods: {
    ...mapActions(["showModal"])
  },
  components: {
    MainLogIn,
    MainRegistration,
    Signup,
    LoginForm,
    SignupForm,
    ResetForm,
    ShareRide,
    PleaseAddTheRideToYourList,
    PleaseLogInInOrderToEditRide,
    DynamicModal,
    LoadingIcon
  }
};
</script>
<style scoped>
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.light-bg {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
}
</style>
