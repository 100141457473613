<template>
  <div class="home">
    <div class="p-0 p-md-5 mb-4 rounded-3 d-flex align-items-center hero-image">
      <div
        style="
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: gray;
          opacity: 0.3;
          z-index: 0;
        "
      ></div>
      <div class="container py-5" style="z-index: 5">
        <div class="row">
          <div class="col text-white text-start d-none d-md-block">
            <h1 class="display-3 font-weight-bold">תכננו את הטיול שלכם</h1>
            <h2 class="display-4">שיתוף טיולים בין אנשים</h2>
            <h2 class="">
              משפחות משתפות טיולים ואתם בוחרים את הנכונים ביותר עבורכם
            </h2>
          </div>
          <div class="col d-none d-md-flex">
            <Tripplanner />
          </div>
          <!-- mobile hero -->
          <div class="col-12 d-md-none">
            <div class="container text-center">
              <h1 class="fw-bold text-white">מתכננים בדרך הטובה והנכונה</h1>
              <button class="btn d-block mx-auto text-start my-2">
                <h4 class="text-warning">יעד</h4>
                <h5 class="text-white">הכנס עיר, מדינה</h5>
              </button>
              <button
                class="btn btn-lg bg-warning text-white px-4 fs-2 fw-bold"
              >
                חפשו לי טיול
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-4 py-5 text-center d-none d-md-block">
      <div class="container border-bottom py-5">
        <div class="col-5 mx-auto">
          <div class="row">
            <div class="col">
              <label
                for="searchInput"
                class="form-label d-flex text-marigold fs-4"
                >יעד</label
              >
              <div class="input-group">
                <input
                  id="searchInput"
                  type="text"
                  class="
                    form-control
                    rounded-0
                    border-0 border-bottom border-marigold
                  "
                  placeholder="אוסטריה , ויאנה"
                />
                <span
                  class="
                    input-group-text
                    bg-transparent
                    border-0 border-bottom border-marigold
                    me-3
                  "
                >
                  <i class="bi bi-map text-marigold fs-4"></i>
                </span>
                <button
                  type="button"
                  class="
                    btn
                    rounded
                    btn-lg
                    px-4
                    bg-downy
                    text-white
                    fw-bold
                    shadow-sm
                  "
                >
                  חיפוש
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <InstructionCard />
    <div class="px-4 py-5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="text-center fw-bold">טיולים נפוצים</h2>
            <LocationCard />
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 py-5 bg-light d-none d-md-block">
      <div class="container">
        <div class="row">
          <div class="col" v-for="(feature, index) in features" :key="index">
            <div class="card border-0 bg-light">
              <div class="d-flex justify-content-center">
                <component :is="feature.icon"></component>
              </div>
              <div class="card-body">
                <h5>{{ feature.title }}</h5>
                <p class="card-text">
                  {{ feature.body }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sponsors />
    <Footer />
  </div>
</template>

<script>
import InstructionCard from "@/components/InstructionCard.vue";
import InstructionCard1 from "@/components/InstructionCard1.vue";
import LocationCard from "@/components/LocationCard.vue";
import LocationCard1 from "@/components/LocationCard1.vue";
import CompaniesCard from "@/components/CompaniesCard.vue";
import Footer from "@/components/Footer.vue";
import GlobalMap from "@/components/GlobalMap";
import { mapActions, mapGetters } from "vuex";
import GlobalRides from "@/components/GlobalRides";
import Tripplanner from "../components/svg/Tripplanner.vue";
import Sponsors from "../components/Sponsors.vue";
const loadIcon = icon => {
  return import(`../components/svg/${icon}.vue`);
};

export default {
  name: "HomePage",
  components: {
    InstructionCard,
    InstructionCard1,
    LocationCard,
    LocationCard1,
    CompaniesCard,
    Footer,
    GlobalRides,
    GlobalMap,
    SearchBar: () => import("@/components/SearchBar.vue"),
    Tripplanner,
    Sponsors
  },
  data() {
    return {
      gMapPlace: "",
      jumbotronHeight: "",
      alignLocation: "center",
      value1: 2,
      value2: 2,
      value3: 0,
      selectsOptions: {
        first: {
          label: { text: "ימים" },
          options: ["1", "2", "3", "4", "5", "6", "7", "14", "21"]
        },
        second: {
          label: { text: "םישנא" },
          options: ["choice 1", "choice 2", "choice 3"]
        },
        third: {
          label: { text: "םוקימ ןזה" },
          options: ["choice 1", "choice 2", "choice 3"]
        }
      },
      selects: [
        [{ text: "םימי", value: null }, "choice 1", "choice 2", "choice 3"],
        [{ text: "םישנא", value: null }, "choice 1", "choice 2", "choice 3"],
        [{ text: "םוקימ ןזה", value: null }, "choice 1", "choice 2", "choice 3"]
      ],
      features: [
        {
          icon: () => loadIcon("BoxesIcon"),
          title: "מאגר גדול של אטרקציות וטיולים",
          body: "בסיס גדול של טיולים ושל אטרקציות שאנשים עשו ובוחרים לשתף"
        },
        {
          icon: () => loadIcon("DocumentIcon"),
          title: 'כל הטיולים בוצעו ע"י מטיילים אחרים',
          body:
            'אין כמו המלצות ממטילים שכבר עשו את המסלול, כל המסלולים בוצעו ע"י מטילים'
        },
        {
          icon: () => loadIcon("TodosIcon"),
          title: "עריכה קלה של הטיול",
          body: "ניתן להוסיף או להוריד אטרקציות לאחר בחירת טיול"
        },
        {
          icon: () => loadIcon("TagPlusIcon"),
          title: "הזמנה קלה ונוחה של מלונות",
          body: "הזמנת מלונות לכל הטיול במקום אחד"
        }
      ]
    };
  },
  computed: mapGetters(["isAppLoggedIn"]),
  methods: {
    ...mapActions(["fetchGlobalRides", "openRideInformationPage"]),
    getImageHeight(imageLoadedCb) {
      let image = new Image();
      image.onload = () => {
        imageLoadedCb(image.height);
      };
      image.src = require("../assets/images/header.png");
    },
    getHeight(height) {
      this.jumbotronHeight = height + "px";
    },
    serchattraction() {
      this.$router.push("/search_ride");
    }
  },
  mounted() {
    this.getImageHeight(this.getHeight);
    if (window.innerWidth > 768) {
      this.alignLocation = "end";
    } else {
      this.alignLocation = "center";
    }
  }
};

// onSignIn(googleUser) {
//     var profile = googleUser.getBasicProfile();
//     console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
//     console.log('Name: ' + profile.getName());
//     console.log('Image URL: ' + profile.getImageUrl());
//     console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
// },
// signOut() {
//     console.log('start User signed out.')
//     var auth2 = gapi.auth2.getAuthInstance();

//     auth2.signOut().then(function () {
//     console.log('User signed out.');
//     });
// }

// beforeCreate() {
//   this.$loadScript("https://apis.google.com/js/platform.js")
//   .then(() => {
//     console.log( "AVSHALOM load the script")
//   })
//   .catch(() => {
//     console.log( "AVSHALOM did not load the script")
//   });
// }
</script>





<style scoped>
.hero-image {
  position: relative;
  background-image: url("../assets/images/header.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 10em;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .hero-image {
    min-height: 16em;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .hero-image {
    min-height: 743px;
  }
}
.bg-cover {
  background-attachment: static;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.jumbotron {
  background: url("../assets/images/header.png") no-repeat;
  background-size: cover;
  position: relative;
}
@media only screen and (min-width: 1200px) {
  /* .jumbotron {
    background-position: 100% 100% !important;
    background-size: cover;
    background-origin: padding-box !important;
  } */
}
@media only screen and (min-width: 1024px) {
  /* .jumbotron {
    background-position: 25% 100%;
    background-origin: padding-box !important;
  } */
}
@media only screen and (min-width: 768px) {
  /* .jumbotron {
    background-position: 30% center;
    background-origin: content-box;
  } */
}
@media only screen and (max-width: 425px) {
  /* .jumbotron {
    background-position: 25% center;
    background-origin: content-box;
  } */
}

.light-bg {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(30px);
}

.custom-select {
  width: 100%;
  border: 0;
  padding: 10px 10px;
  background-color: transparent;
  border-bottom: 1px solid #929fba !important;
  border-radius: 0;
}
.lead-head {
  font-family: Assistant;
  font-style: normal;
  font-weight: 800;
  font-size: 29px;
  line-height: 126.3%;
  text-align: right;
  letter-spacing: 0.045em;
  color: #0d2f29;
}
.lead-head-sm {
  font-weight: bold;
  font-size: 30px;
  line-height: 126.3%;
  letter-spacing: 0.045em;
  color: #0d2f29;
}
.lead-follow {
  font-family: Assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 126.3%;
  text-align: right;
  letter-spacing: 0.045em;
  color: #0d2f29;
}
.lead-follow-sm {
  font-family: Assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 126.3%;
  text-align: center;
  letter-spacing: 0.045em;
  color: #0d2f29;
}
@media (max-width: 575.98px) {
  .dropdown-width {
    width: 75% !important;
  }
}
.expand-arrow {
  background: #fff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' %3E%3Cpath d='M 44.988281 13.984375 C 44.726563 13.992188 44.476563 14.101563 44.292969 14.292969 L 25 33.585938 L 5.707031 14.292969 C 5.519531 14.097656 5.261719 13.992188 4.992188 13.988281 C 4.582031 13.992188 4.21875 14.238281 4.0625 14.613281 C 3.910156 14.992188 4 15.421875 4.292969 15.707031 L 24.292969 35.707031 C 24.683594 36.097656 25.316406 36.097656 25.707031 35.707031 L 45.707031 15.707031 C 46.003906 15.421875 46.09375 14.980469 45.9375 14.601563 C 45.777344 14.222656 45.402344 13.976563 44.988281 13.984375 Z' %3E%3C/path%3E%3C/svg%3E")
    no-repeat;
  padding: 0.4em;
}
</style>
