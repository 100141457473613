import * as VueGoogleMaps from "vue2-google-maps";
import Vue from "vue";
import App from "./App";
import VueRouter from "vue-router";
import store from "./store";
import config from "@/common/config";

import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import "./assets/styles.css";

import About from "@/views/About";
import TermsOfUse from "@/views/TermsOfUse";
import AuthHandler from "./components/AuthHandler";
import HomePage from "@/views/HomePage";
import Accommodation from "@/views/Accommodation";
import BookingDotCom from "@/views/BookingDotCom";
import SingleRideWithMap from "@/views/SingleRideWithMap";
import RideChecklist from "@/views/RideChecklist";
import SerchAttractionsWithMap from "@/views/SerchAttractionsWithMap";
import EditingAtractionsUniqnes from "@/views/EditingAtractionsUniqnes";
import SearchRide from "@/views/SearchRide";
import Attraction from "@/views/Attraction";
import AttractionInformation from "@/views/AttractionInformation";
import AttractionEditing from "@/views/AttractionEditing";
import RideInformations from "@/views/RideInformation";
import UserRidesV from "@/views/UserRides";
import UploadForm from "./components/UploadForm";
import SingleRide from "./components/SingleRide";
import SingleRideMap from "./components/SingleRideMap";
import GlobalMap from "./components/GlobalMap";
import LoaderPlugin from "vue-google-login";
import SerchAttractions from "./components/SerchAttractions";
import CreateAttraction from "./components/CreateAttraction";
import UserRides from "./components/UserRides";
import RideInformation from "./components/RideInformation";
import LoadScript from "vue-plugin-load-script";
import VueAnalytics from "vue-analytics";
import GlobalRides from "./components/GlobalRides";
import Popups from "./components/Popups";

// import '@babel/polyfill'
// import 'mutationobserver-shim'
import VueSocialSharing from "vue-social-sharing";
import VueTextareaAutosize from "vue-textarea-autosize";
import firebase from "firebase";

import VideoBackground from "vue-responsive-video-background-player";
// import VueMeta from 'vue-meta'

Vue.component("video-background", VideoBackground);

var firebaseConfig = {
  apiKey: config.config.FIRE_BASE_API_KEY,
  authDomain: "tripplanner-308b3.firebaseapp.com",
  projectId: "tripplanner-308b3",
  storageBucket: "tripplanner-308b3.appspot.com",
  messagingSenderId: "869000350964",
  appId: "1:869000350964:web:4afb57434764563e1ee2a1",
  measurementId: "G-2LETZP7VRD",
};

Vue.use(VueSocialSharing);
Vue.use(VueTextareaAutosize);
Vue.use(VueSocialSharing);
Vue.use(VueTextareaAutosize);
Vue.config.productionTip = false;
// Vue.use(LoaderPlugin, {
//   client_id: '80587839075-a34ut0489s6kljp5rtn5c8t45qqcacmc.apps.googleusercontent.com'
// });

Vue.use(VueRouter);
// Vue.use(VueMeta)
Vue.use(LoadScript);
const singleRideName = "single ride with map with id";
export const router = new VueRouter({
  mode: "history",
  routes: [
    { name: "home", path: "/", component: HomePage },
    { name: "about", path: "/about", component: About },
    { name: "terms of use", path: "/terms_of_use", component: TermsOfUse },
    { name: "upload", path: "/upload", component: UploadForm },
    {
      name: "oauth2callback",
      path: "/oauth2/callback",
      component: AuthHandler,
    },
    { name: "single_ride", path: "/single_ride", component: SingleRide },
    {
      name: "single ride map",
      path: "/single_Ride_map",
      component: SingleRideMap,
    },
    {
      name: "single ride with map",
      path: "/single_ride_with_map",
      component: SingleRideWithMap,
    },
    {
      name: singleRideName,
      path: "/single_ride_with_map/:id",
      component: SingleRideWithMap,
    },
    {
      name: "editing atractions uniqnes",
      path: "/editing_atractions_uniqnes",
      component: EditingAtractionsUniqnes,
    },
    {
      name: "ride checklist",
      path: "/ride_checklist",
      component: RideChecklist,
    },
    {
      name: "ride checklist with id",
      path: "/ride_checklist/:id",
      component: RideChecklist,
    },
    // We should remove the global rides
    { name: "global rides", path: "/global_rides", component: GlobalRides },
    //can we remove this?
    { name: "global map", path: "/global_map", component: GlobalMap },
    {
      name: "serch attraction",
      path: "/serch_attractions",
      component: SerchAttractions,
    },
    {
      name: "serch attraction with map",
      path: "/serch_attractions_with_map",
      component: SerchAttractionsWithMap,
    },
    { name: "serch ride", path: "/search_ride", component: SearchRide },
    {
      name: "add new attraction to ride",
      path: "/add_new_attraction_to_ride_old",
      component: CreateAttraction,
    },
    {
      name: "attraction editing",
      path: "/add_new_attraction_to_ride",
      component: AttractionEditing,
    },
    { name: "attraction", path: "/attraction", component: Attraction },
    {
      name: "attractionInforation",
      path: "/attractionInformation",
      component: Attraction,
    },
    { name: "accommodation", path: "/accommodation", component: Accommodation },
    { name: "booking dot com", path: "/booking", component: BookingDotCom },
    // We should remove all the old part!!!!!!!!!!!!!
    { name: "user rides old", path: "/user_rides_old", component: UserRides },
    { name: "user rides", path: "/user_rides", component: UserRidesV },
    {
      name: "the old ride information",
      path: "/ride_information_old",
      component: RideInformation,
    },
    {
      name: "ride information",
      path: "/ride_information",
      component: RideInformations,
    },
    { name: "popups", path: "/popups", component: Popups },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.name == singleRideName) {
      var reload = parseInt(
        localStorage.getItem("workAroundToSetTheScroleLocationAtTheMAinPage")
      );
      return { x: 0, y: reload };
    }
    return { x: 0, y: 0 };
  },
});

Vue.use(VueAnalytics, {
  id: "UA-52149787-1",
  router,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: config.config.GOOGLE_API_KEY,
    libraries: "places", // necessary for places input,
    language: "iw",
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),

  created() {
    firebase.initializeApp(firebaseConfig);
    firebase.auth().useDeviceLanguage();
  },
}).$mount("#app");
