import config from '../../common/config';

const state = {
  rideCenter: { lat: 45.508, lng: -73.587 },
  rideMarkers: [],
  places: [],
  rideCurrentPlace: null,
  rideAppMap: null,
  ridePresentedDay: null,

};
const getters = {
  rideCenter: state => state.rideCenter,
  rideMarkers: state => state.rideMarkers,
  places: state => state.places,
  rideCurrentPlace: state => state.rideCurrentPlace,
  rideAppMap: state => state.rideAppMap,
  ridePresentedDay: state => state.ridePresentedDay,
};
const actions = {
  rideAddMarkerByLatAndLng: ({dispatch, commit, getters }, point) => {
    const rideAppMap = getters.rideAppMap;
    const rideMarkers = getters.rideMarkers;
    dispatch( 'addMarkerByLatAndLng', { 
      map:rideAppMap,
      rideMarkers: rideMarkers,
      point:point
     });
  },
  AddAccommodationMarkerByIndex: ({ dispatch, getters }, accommodationParameters ) => {
    const daylyAttraction = getters.allRide.attractionsByDay[ accommodationParameters.dayIndex ];
    var evningAccommodation = null
    if ( Object.keys( daylyAttraction.accommodation ).length ){
      evningAccommodation = daylyAttraction.accommodation
    }
    else if ( Object.keys( daylyAttraction.base_trip_accommodation ).length ){
      evningAccommodation = daylyAttraction.base_trip_accommodation
    }
    if ( evningAccommodation ){
      var lat = parseFloat( evningAccommodation.latitude);
      var lng = parseFloat( evningAccommodation.longtitude);
      if (lat && lng ){
        dispatch( 'rideAddMarkerByLatAndLng', { latPoint: lat,
                                                lngPoint: lng,
                                                name:accommodationParameters.name,
                                                iconUrl: accommodationParameters.iconUrl });
      }
    }  
  },
  rideUpdateMarkerOfDay: ({ dispatch, state, commit, getters}, dayIndex ) => {
    const daylyAttraction = getters.allRide.attractionsByDay[dayIndex];
    var i;
    if( ! daylyAttraction ){return;}
    //all the attraction at the day
    for(i=0; i < daylyAttraction.daily_attractions.length; i++ ){
      var lat = parseFloat(daylyAttraction.daily_attractions[i].latitude);
      var lng = parseFloat(daylyAttraction.daily_attractions[i].longtitude);
      dispatch( 'rideAddMarkerByLatAndLng', { latPoint: lat,
                                              lngPoint: lng,
                                              name:daylyAttraction.daily_attractions[i].title,
                                              iconUrl: config.config.ROOT_URL + '/static/images/map_vector.png'});
    }
    dispatch( 'AddAccommodationMarkerByIndex', {  dayIndex: dayIndex,
                                                  name:"מקום לינה",
                                                  iconUrl: config.config.ROOT_URL + '/static/images/map_vector_home.png'});
    if( dayIndex > 0 && daylyAttraction.accommodation.firestDayAtThisAccommodation ){
      dispatch( 'AddAccommodationMarkerByIndex', {  dayIndex: dayIndex-1,
                                                    name:"לינה יום קודם",
                                                    iconUrl: config.config.ROOT_URL + '/static/images/map_vector_home.png'});
    }
  },
  rideRemoveAllMarkers: ({dispatch, commit, getters}) =>{
    const rideMarkers = getters.rideMarkers;
    dispatch( 'removeAllMarkers', getters.rideMarkers );

  },
  async rideUpdateMarkerByScrollerLocation({dispatch ,state, commit, getters }){
    // console.log( "we are updating the pa for day:")
    var dayToUpdate = await dispatch( 'rideGetTheFocuesDay' );
    // console.log( dayToUpdate )
    const ridePresentedDay = await getters.ridePresentedDay;
    if ( ridePresentedDay == dayToUpdate ){return}
    dispatch('rideRemoveAllMarkers');
    commit( 'rideSetPresentedDay', dayToUpdate );
    // TODO we should remove this and help the application to load the map at the first day upload after refresh
    if( ! getters.allRide.attractionsByDay ){return;}
    dispatch( 'rideUpdateMarkerOfDay', dayToUpdate );
    dispatch( 'rideFitBoundesToMarkers');
    
  },
  async rideGetTheFocuesDay({getters}){
    const daylyAttraction = await getters.allRide.attractionsByDay;
    if( ! daylyAttraction ){return 1000;}// in order to update at the first time that the map is lowded
    var i;
    for(i=0; i < daylyAttraction.length; i++ ){
      const dailyAttractionItem = document.getElementById('daily_attraction_day_' + i );
      if( ! dailyAttractionItem ){return 0;}
      if ( dailyAttractionItem.getBoundingClientRect().y > 210 ){// tis si the topest item
        if ( i == 0 ){
          return 0;
        }
        return i-1;
      }
    }
    return 0;
  },

  rideFitBoundesToMarkers: ({getters, dispatch}) => {
    const rideAppMap = getters.rideAppMap;
    const rideMarkers = getters.rideMarkers;
    dispatch( 'fitBoundesToMarkers', { 
      appMap:rideAppMap,
      markers: rideMarkers
      });
    },
  rideAddMarker: ({dispatch ,state, commit }) => {
    if (state.rideCurrentPlace) {
      const marker = {
        lat: state.rideCurrentPlace.geometry.location.lat(),
        lng: state.rideCurrentPlace.geometry.location.lng()
      };
      commit( 'rideAddMarker', marker );
      commit( 'addRidePlace', state.rideCurrentPlace );
      commit( 'setrideCenter', marker);
      commit( 'ridesetCurrentPlace', null);
    }
  },
}
const mutations = {
  ridesetCurrentPlace( state, place ) {
    state.rideCurrentPlace = place;
  },
  rideSetAppMap( state, rideAppMap ) {
    state.rideAppMap = rideAppMap;
  },
  rideAddMarker( state, marker ) {
    state.rideMarkers.push( marker );
  },
  cleanRideMarkers( state ) {
    state.rideMarkers = [];
  },
  addRidePlace( state, place ) {
    state.places.push( { place } );
  },
  setrideCenter( state, rideCenter ) {
    state.rideCenter =  rideCenter;
  },
  rideSetPresentedDay( state, dayNumber ) {
    state.ridePresentedDay = dayNumber;
  },
  rideSetPresentedDayIndex( state, map ){
    state.rideListMaps.push( map );
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
