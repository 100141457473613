<template>
  <div>
    <!-- <div>
      <h2>Search and add a pin</h2>
      <label>
        <gmap-autocomplete
          @place_changed="updateCurrentPlace">
        </gmap-autocomplete>
        <button @click="addMarker">Add</button>
      </label>
      <br/>

    </div> -->
    <!-- <b-card-img src= "https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=config.config.GOOGLE_API_KEY&map_ids=5912751282a2bce4" alt="Image"></b-card-img>
    <b-card-img src= "https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&key=config.config.GOOGLE_API_KEY&map_ids=5912751282a2bce4" alt="Image"></b-card-img> -->
    <!-- <b-card-img src= "https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=config.config.GOOGLE_API_KEY&map_id=5912751282a2bce4"></b-card-img> -->
<!-- https://maps.googleapis.com/maps/api/place/details/json?fields=name%2Crating%2Cformatted_phone_number&place_id=ChIJPR17J93IdkcRMyFaHISYmtU&key=config.config.GOOGLE_API_KEY -->

    <div v-if="isMobile()">
      <gmap-map
        ref="mapRef"
        :center="rideCenter"
        style="width:100%;  height: 100px;"
      >
      </gmap-map>
    </div>
    <div v-else>
      <gmap-map
        ref="mapRef"
        :center="rideCenter"
        style="width:100%;  height: 400px;"
      >
        <!-- <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
        ></gmap-marker> --> 
      </gmap-map>
    </div>
  </div>
</template>



<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SingleRideMap',
  computed: mapGetters(['rideMarkers', 'rideCenter']),
  methods: 
  {
    ...mapActions(['setPlace', 'geolocate','rideSetAppMap']),
    updateCurrentPlace (place) {
      this.$store.commit('ridesetCurrentPlace', place)
    },
    isMobile () {
      return ( window.innerWidth < 768 )
    },
    
  },
  mounted(){
    this.$refs.mapRef.$mapPromise.then((map) =>{
      this.$store.commit('rideSetAppMap', map);

    });    
  }
};
</script>
