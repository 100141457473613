<template>
  <div class="home" style="position:relative;">
    <SingleRideHeader />
    <div class="sticky-top sticky first">
      <InnerNav />
    </div>
    <b-container class="py-5">
      <b-row no-gutters >
        <b-col cols="12" class="d-lg-none text-center d-md-block mb-5 sticky-top sticky second">
          <div v-if="isMobile()" class="shadow-sm">
            <SingleRideMap />
          </div >
        </b-col>
        <b-col cols="12" lg="9" md="12">
          <AttractionUniqnes/>
        </b-col>
        <b-col cols="3" class="d-md-none d-none d-lg-block">
          <div class="sticky-top sticky second">
          <div v-if="isNotMobile()" >
            <b-card class="border-0 p-0 sticky second">
              <SingleRideMap />
            </b-card>
          </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import AttractionUniqnes from "@/components/AttractionUniqnes";
import SingleRideMap from "@/components/SingleRideMap";
import SingleRideHeader from "@/components/SingleRideHeader";
import InnerNav from "@/components/InnerNav";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditingAtractionUniqnes",
  components: {
    AttractionUniqnes,
    SingleRideMap,
    Footer,
    SingleRideHeader,
    InnerNav,

  },
  methods:{
    ...mapActions([]),
      isMobile () {
        return ( window.innerWidth < 768 )
    },
    // We should move it to common!!!!!!!!!!!!!!!
      isNotMobile () {
      return ( window.innerWidth >= 768 )
    },

  },

};
</script>

<style scoped>
.sidebar {
  position: fixed;
  min-width: 100px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.first {
  height:70px;
  background:white;
  position:sticky;
  top:48px;
}

.second {
  height:50px;
  position:sticky;
  top:118px;
}
</style>
