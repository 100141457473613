<template>
  <div>
    <div align="center" data-id=viator-banner data-partner-id=P00053210 data-url=https://www.viator.com/Vienna-tours/Outdoor-Activities/d454-g9 data-banner-width=728 data-banner-height=90 data-banner-language=en data-banner-selection=banner1 data-campaign=austria-salzburg></div>
    <!-- <div data-vi-partner-id=P00053210 data-vi-language=en data-vi-currency=EUR data-vi-partner-type="AFFILIATE" data-vi-url="https://www.viator.com/Vienna-tours/Outdoor-Activities/d454-g9"  data-vi-total-products=3 data-vi-campaign="austria-salzburg"></div> -->



    <div v-if='allRide.ride'>      
      <h2>
        {{allRide.ride.title}}
      </h2>
      <div class="d-flex justify-content-between">
         <h6>
        {{allRide.ride.authorName}}
      </h6>
      <h6>
        {{allRide.ride.start_date}}-{{allRide.ride.end_date}}
      </h6>
      </div>
     
      <h6>
        {{allRide.ride.text}}
      </h6>      
      <div>
        <div v-for="(star, index) in allRide.highLevelRideLocations" :key="index" class="py-2">
          <h5>
            {{star.locationName}}
          </h5>
          <div class="py-2"  v-if="index < (allRide.highLevelRideLocations.length - 1)">
              <div class="row g-0">
                <div
                  class="
                    col-auto
                    offset-1
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <VerticalLineIcon class="text-black" />
                </div>                
                <div class="col-4 px-4 d-flex align-items-center">
                  <span class="fs-3 text-warning">{{star.numberOfDays}}</span>
                </div>
              </div>
            </div>          
        </div>
      </div>
      <div>
        <div v-for="(star, index) in allRide.highLevelRideLocations" :key="index">
          <h5>
            lat:{{star.lat}}, lng:{{star.lng}}
          </h5>
        </div>
      </div>
      <!-- <div id="map_canvas" style="background-color:red; width:700px; height:500px; margin-left:80px; padding:3em;"></div> -->
    </div>

    <template v-for="(dailyActivites, index) in allRide.attractionsByDay">      
      <div :id="'daily_attraction_day_' + index">
       <button class="btn btn-lg btn-light border d-block mx-auto my-2">
              וינה
            </button>
            <button class="btn btn-lg btn-light border d-block mx-auto my-2">
              יום מספר {{ index + 1 }}
            </button>
            <div class="d-flex justify-content-center py-2">
              <VerticalLineIcon />
            </div>
       <!--  <div class="container border border-danger">
          <div class="row">
            <div class="col">
              <h5
                class="text-right font-weight-bold"
                style="color: #34d0b6"
              >
                {{ index + 1 }} יום מספר
              </h5>
            </div>
            <div v-if="!dailyActivites.daily_attractions.length  && compareToUserId( allRide.ride.authorId )" class="row text-right font-weight-bold" cols="12">
              <div class="mr-3">
                בינתיים יום זה פנוי
                <button class="btn btn-lg btn-light border d-block mx-auto my-2 bg-warning rounded-pill" @click="goToNewAttraction(index)">הוספת פעילות חדשה</button> או 
                <button class="btn btn-lg btn-light border d-block mx-auto my-2 bg-warning rounded-pill" @click="serchAttractionToRide(index)">בחירה ממאגר הפעילויות שלנו</button>
              </div>
            </div>
            <div v-else-if="compareToUserId( allRide.ride.authorId )" class="col text-right font-weight-bold" cols="12">
              <div class="mr-3">
                <button class="btn btn-lg btn-light border d-block mx-auto my-2 bg-warning rounded-pill" @click="goToNewAttraction(index)">הוספת פעילות חדשה</button> או 
                <button class="btn btn-lg btn-light border d-block mx-auto my-2 bg-warning rounded-pill" @click="serchAttractionToRide(index)">בחירה ממאגר הפעילויות שלנו</button>
              </div>
            </div>
            <div v-else cols="12" class="col text-right font-weight-bold">
              <div class="d-flex py-3">
                <button
                  @click="serchAttractionToRide(index)"
                  class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3 bg-warning rounded-pill"
                >צפו בפעילויות שניתן להוסיף ליום זה</button>
              </div>
            </div>
            <div v-if="dailyActivites.daily_attractions.length && compareToUserId( allRide.ride.authorId )" class="col text-right d-flex justify-content-end align-items-center" cols="12">
              <div class="mr-3" v-if="dailyActivites.accommodation.id">
                <a href @click="goToRideAccommodation( dailyActivites.accommodation )">עריכת ההזמנה הקיימת</a>
              </div>
              <div class="mr-3" v-else>
                <a href @click="goToBaseRideAccommodation( dailyActivites.base_trip_accommodation )">בצעה הזמנת לינה </a> או 
                <a href @click="goToBaseRideAccommodation( dailyActivites.base_trip_accommodation )">הוסף הזמנה קיימת</a>
              </div> -->
              <!-- <h1> {{dailyActivites.base_trip_accommodation}}</h1> -->
              <!-- <div class="shadow rounded-circle p-2">
                <img
                  :src="
                          require('@/assets/images/icons/icons8-hotel-bed-50.png')
                        "
                  width="24"
                  height="24"
                />
              </div>
            </div>
          </div>
        </div> -->
        <div 
          v-for="(attraction, idx) in dailyActivites.daily_attractions"
          :key="idx" class="
                row
                g-0
                border
                rounded
                overflow-hidden
                flex-md-row
                mb-4
                shadow-sm
                py-3 py-md-0
              "
            >
              <!-- begin mobile view -->
              <div class="col-12 order-2 d-md-none text-center">
                <h1 class="fw-bold">נחיתה בוינה</h1>
                <div class="d-flex justify-content-center gap-3 mb-2">
                  <span class="me-2"
                      >
                     <StarRating :inline="true" :show-rating="false" :rtl="true" :read-only="true" :star-size="20" :rating="attraction.rate" :increment="0.01"/>
                    </span>
                    <strong>דירוג {{attraction.rate}}</strong>
                </div>
                <button class="btn btn-lg bg-warning fw-bold text-white">
                  צפייה בטיסה
                </button>
              </div>
              <!-- end of mobile view -->
              <div
                class="col-12 order-2 col-md-8 order-md-1 p-4 d-none d-md-flex flex-column"
              >
                <div class="row">
                  <div class="col-8 d-flex">
                    <h5 class="fw-bold">{{attraction.title}} - </h5>
                    <h5>יום מספר {{ index + 1 }}</h5>
                  </div>
                  <div class="col-4">
                    <span class="me-2"
                      >
                     <StarRating :inline="true" :show-rating="false" :rtl="true" :read-only="true" :star-size="20" :rating="attraction.rate" :increment="0.01"/>
                    </span>
                    <strong>דירוג {{attraction.rate}}</strong>
                  </div>
                </div>
                <Collapse
                            :text="attraction.text"
                            :uniqueId="Math.floor(Math.random() * (1000 - 10) ) + 10"
                          />
                <div class="row mt-auto">
                  <div class="col">
                    <div class="row">
                      <div class="col-9">
                        <button class="btn btn-lg bg-warning w-100 fw-bold">
                          צפייה בטיסה
                        </button>
                      </div>
                      <div class="col-3">
                        <span
                          class="p-2 d-flex justify-content-center rounded-3"
                          style="background-color: #33ccff"
                        >
                          <waze-sm-icon style="width: 2em" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <h5>שעות</h5>
                    <p>12:00 - 14:30</p>
                  </div>
                </div>
              </div>
              <div
                class="
                  col-12
                  order-1
                  col-md-4
                  order-md-2
                  d-md-block d-flex
                  justify-content-center
                  p-2
                "
              >
               <img
            v-if="attraction.image"
            :src="attraction.image"
            alt="Image"
            class="rounded"
            style="
            max-width: 100%;
            display: block;
            height: auto;"
            >
            <span v-if="attraction.imageHtmlAttributions" v-html="getImageHtmlAttributions( attraction.imageHtmlAttributions[0])"></span>
              </div>
            </div>
        <div class="container" v-if="( Object.keys( activAccommodation( dailyActivites ) ).length )">
          <!-- <div class="row">
            <div class="col-3">
              <a href @click="goToBokingDotCom( activAccommodation( dailyActivites ) )">מחירים</a>
              </div>
            <div class="col-9"  >
              <div class="text-right" v-if=" activAccommodation( dailyActivites ).cuntry_and_city">
                <h4>{{activAccommodation( dailyActivites ).cuntry_and_city}} - לינה ב </h4>
              </div>
              <div>
                <h4>{{activAccommodation( dailyActivites ).check_in_date}} - {{activAccommodation( dailyActivites ).check_out_date}} </h4>
              </div>
            </div>
            <div class="col-1 text-right" >
            <img
              :src="require('@/assets/images/icons/icons8-hotel-bed-50.png')"
              width="24"
              height="24"
            />
            </div>
          </div> -->
          <div          
          v-if="activAccommodation( dailyActivites ).image && activAccommodation( dailyActivites ).firestDayAtThisAccommodation"
              class="
                row
                g-0
                border
                rounded
                overflow-hidden
                flex-md-row
                mb-4
                shadow-sm
                py-3 py-md-0
              "
            >
              <!-- begin mobile view -->
              <div class="col-12 order-2 d-md-none text-center">
                <h1 class="fw-bold">בוינה</h1>
                 <h4 class="text-right font-weight-bold"> {{ activAccommodation( dailyActivites ).name }}</h4>
                <p>19/9 - 21/9</p>
                <div class="d-flex justify-content-center gap-3 mb-2">
                  <span
                    ><i v-for="i in 5" :key="i" class="bi bi-star-fill"></i
                  ></span>
                  <span>דירוג 3.5</span>
                </div>
                <div class="d-flex gap-2 justify-content-center">
                  <span class="p-2 rounded-3" style="background-color: #33ccff">
                    <waze-sm-icon style="width: 2em" />
                  </span>
                  <button class="btn btn-lg bg-warning fw-bold text-white">
                    צפייה בטיסה
                  </button>
                </div>
              </div>
              <!-- end of mobile view -->
              <div
                class="col-12 order-2 col-md-8 order-md-1 p-4 d-none d-md-block"
              >
                <div class="row">
                  <div class="col">
                    <strong class="text-success">לינה</strong>                    
                 <h4 class="text-right font-weight-bold"> {{ activAccommodation( dailyActivites ).name }}</h4>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-8">
                    <Collapse
                            :text="activAccommodation( dailyActivites ).text"
                            :uniqueId="Math.floor(Math.random() * (1000 - 10) ) + 10"
                          />
                  </div>
                  <div class="col-4">
                    <button class="btn btn-lg bg-warning w-100 fw-bold">
                      צפייה בטיסה
                    </button>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-8">
                    <h4 class="mb-0">בתאריכים 19/9 - 21/9</h4>
                  </div>
                  <div class="col-4 d-flex justify-content-center">
                    <span
                      class="p-2 rounded-3 w-25"
                      style="background-color: #33ccff"
                    >
                      <waze-sm-icon style="width: 2em" />
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="
                  col-12
                  order-1
                  col-md-4
                  order-md-2
                  d-md-block d-flex
                  justify-content-center
                "
              >
                <img
                      :src="activAccommodation( dailyActivites ).image"
                      alt="Image"
            class="rounded"
            style="
            max-width: 100%;
            display: block;
            height: auto;"
                      >
              </div>
            </div>
            <div v-else
              class="
                row
                g-0
                border
                rounded
                overflow-hidden
                flex-md-row
                mb-4
                shadow-sm
                py-3 py-md-0
              "
            >
              <!-- begin mobile view -->
              <div class="col-12 d-md-none text-center">
                <h4 class="fw-bold fs-3">לינה</h4>
                 <h4 class="text-right font-weight-bold"> {{ activAccommodation( dailyActivites ).name }}</h4>
                <h4 class="mb-0">19/9 - 21/9</h4>
                <div class="d-flex gap-2 justify-content-center">
                  <span class="p-2 rounded-3" style="background-color: #33ccff">
                    <waze-sm-icon style="width: 2em" />
                  </span>
                  <button class="btn btn-lg bg-warning fw-bold text-white">
                    צפייה בטיסה
                  </button>
                </div>
              </div>
              <!-- end mobile view -->
              <div class="col p-4 flex-column position-static d-none d-md-flex">
                <div class="row">
                  <div class="col">
                    <strong class="text-success">לינה</strong>
                 <h4 class="text-right font-weight-bold"> {{ activAccommodation( dailyActivites ).name }}</h4>
                  </div>
                  <div class="col-4">
                    <div class="row">
                      <div class="col-9">
                        <button class="btn btn-lg bg-warning w-100 fw-bold">
                          צפייה בטיסה
                        </button>
                      </div>
                      <div class="col-3 d-flex justify-content-center">
                        <span
                          class="p-2 rounded-3"
                          style="background-color: #33ccff"
                        >
                          <waze-sm-icon style="width: 2em" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-8">
                    <h4 class="mb-0">הוסטל חביב שקרוב יחסית למרכז</h4>
                  </div>
                  <div class="col-4">
                    <h4 class="mb-0">בתאריכים 19/9 - 21/9</h4>
                  </div>
                </div>
              </div>
            </div>
          <!-- <div
            class="card my-4 border-0"
          >
          <div class="row g-0">
            <div class="d-none d-md-block col-3 col-md-4">
              <img
              v-if="activAccommodation( dailyActivites ).image && activAccommodation( dailyActivites ).firestDayAtThisAccommodation"
              :src="activAccommodation( dailyActivites ).image"
              alt="Image"
              class="rounded"
              style="
              max-width: 100%;
              display: block;
              height: auto;"
              >
            </div>
            <div class="col-12 col-md-8">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col">
                      </div>
                      <div class="col-auto">
                          <h4 class="text-right font-weight-bold">
                            {{ activAccommodation( dailyActivites ).name }}
                          </h4>
                      </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <Collapse
                            :text="activAccommodation( dailyActivites ).text"
                            :uniqueId="Math.random()"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12" v-if="activAccommodation( dailyActivites ).link">
                      <div class="my-3 text-right">
                        <a
                          :href= "addhttp( activAccommodation( dailyActivites ).link )"
                          target="_blank">
                          <u>לינק למקום הלינה</u>
                        </a>
                      </div>
                    </div>
                    <div class="col-12 my-3 d-flex d-md-none">
                      <img
                      v-if="activAccommodation( dailyActivites ).image && activAccommodation( dailyActivites ).firestDayAtThisAccommodation"
                      :src="activAccommodation( dailyActivites ).image"
                      alt="Image"
                      class="rounded"
                      style="
                      max-width: 100%;
                      display: block;
                      height: 50vh;"
                      >
                    </div>
                    <div class="col-12 align-self-end" v-if="nevigateMode">
                      <div class="d-flex py-3">
                        <button
                          @click="openWazeLink( activAccommodation( dailyActivites ) )"
                          class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3 bg-warning rounded-pill"
                          >נווט לשם באמצעות וויז
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="border-bottom"></span>
          </div> -->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueA11yDialog } from "vue-a11y-dialog";
import Collapse from "@/components/Collapse";
import VerticalLineIcon from "./svg/VerticalLineIcon.vue";
import StarRating from "vue-star-rating";
import WazeSmIcon from "./svg/WazeSmIcon.vue";

export default {
  name: "SingleRide",
  components: {
    Collapse,
    VerticalLineIcon,
    StarRating,
    WazeSmIcon
  },
  computed: mapGetters([
    "allRide",
    "isAppLoggedIn",
    "userId",
    "compareToUserId",
    "nevigateMode"
  ]),
  methods: {
    ...mapActions([
      "fetchRide",
      "addRideToUser",
      "serchAttractionToRide",
      "removeDayFromRide",
      "addNewDayToRide",
      "removeAttractionFromRide",
      "validateAndupdateAppToken",
      "editAttraction",
      "viewAttraction",
      "openLoginForm",
      "goToNewAttraction",
      "goToRideAccommodation",
      "goToBaseRideAccommodation",
      "showModalWithText",
      "goToBokingDotCom"
    ]),
    removeDayFromRideInternal(rideId) {
      if (this.compareToUserId(this.allRide.ride.authorId)) {
        this.removeDayFromRide(rideId);
      } else {
        this.showModalWithText({
          errorNumber: "uesr_is_not_authorize_to_edit_this_ride"
        });
      }
    },
    addNewDayToRideInternal(rideId) {
      if (this.compareToUserId(this.allRide.ride.authorId)) {
        this.addNewDayToRide(rideId);
      } else {
        this.showModalWithText({
          errorNumber: "uesr_is_not_authorize_to_edit_this_ride"
        });
      }
    },
    removeAttractionFromRideInternal(rideId) {
      if (this.compareToUserId(this.allRide.ride.authorId)) {
        this.removeAttractionFromRide(rideId);
      } else {
        this.showModalWithText({
          errorNumber: "uesr_is_not_authorize_to_edit_this_ride"
        });
      }
    },
    openWazeLink(attraction) {
      window.open(
        "https://www.waze.com/ul?ll=" +
          attraction.latitude +
          "%2C" +
          attraction.longtitude +
          "&navigate=yes&zoom=17",
        "_blank"
      );
    },
    addhttp(url) {
      // We have an RIGHT-TO-LEFT MARK at the end of the strint ( at some links )
      // and we should remove them!
      if (!/^(?:f|ht)tps?:/i.test(url)) {
        url = "http://".concat(url);
      }
      return url.replace(/[^\x00-\x7F]/g, "");
    },
    activAccommodation(dailyActivites) {
      if (Object.keys(dailyActivites.accommodation).length) {
        return dailyActivites.accommodation;
      }
      return dailyActivites.base_trip_accommodation;
    },
    getImageHtmlAttributions(htmlCode) {
      return htmlCode.slice(0, 3) + 'target="_blank"' + htmlCode.slice(3);
    },
    truncateString(string) {
      var length = 40;
      let subString = string.substr(0, length);
      let secondStringStartIndex = subString.lastIndexOf(" ");
      let firstString = subString.substr(0, secondStringStartIndex);
      let secondString = string.substr(secondStringStartIndex, string.length);
      return { firstString, secondString };
    }
  },
  created() {
    this.validateAndupdateAppToken();
    this.fetchRide();
  },
  mounted() {
    const reload = localStorage.getItem(
      "workAroundToFixBookingMap_reloadePageInOrdertoCleanTheScript"
    );
    if (reload == "true") {
      localStorage.setItem(
        "workAroundToFixBookingMap_reloadePageInOrdertoCleanTheScript",
        "false"
      );
      location.reload();
    }
    (function(d, sc, u) {
      var s = d.createElement(sc),
        p = d.getElementsByTagName(sc)[0];
      s.type = "text/javascript";
      s.async = true;
      s.src = u + "?v=" + +new Date();
      p.parentNode.insertBefore(s, s.nextSibling);
    })(
      document,
      "script",
      "https://partners.vtrcdn.com/static/scripts/banners/banners.js"
    );

    // (function (d, sc, u) {
    //   var s = d.createElement(sc),
    //      p = d.getElementsByTagName(sc)[0];
    //   s.type = "text/javascript";
    //   s.async = true;
    //   s.src = u + "?v=" + +new Date();
    //   p.parentNode.insertBefore(s, s.nextSibling);
    // })(
    //   document,
    //   "script",
    //   "https://www.viator.com/orion/partner/widget.js"
    // );
  }
};
</script>

<style scoped>
.jumbotron {
  background: url("../assets/images/header.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
@media only screen and (min-width: 1200px) {
  .jumbotron {
    background-position: 100% 100% !important;
    background-size: cover;
    background-origin: padding-box !important;
  }
}
@media only screen and (min-width: 1024px) {
  .jumbotron {
    background-position: 25% 100%;
    background-origin: padding-box !important;
  }
}
@media only screen and (min-width: 768px) {
  .jumbotron {
    background-position: 30% center;
    background-origin: content-box;
  }
}
@media only screen and (max-width: 425px) {
  .jumbotron {
    background-position: 25% center;
    background-origin: content-box;
  }
}
.sticky {
  top: 3em;
}

#container {
  width: 100%;
  height: 100%;
}
#up {
  min-height: 50px;
}
#down {
  height: calc(100% - 50px);
}
.lead-head {
  font-family: Assistant;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 39px;
  text-align: right;
}
.res-row {
  min-height: 235px;
}

@media (max-width: 575.98px) {
  .res-row {
    min-height: 235px;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .res-row {
    min-height: 235px;
  }
}
@media (min-width: 1024.98px) {
  .res-row {
    min-height: 283px;
  }
}
.dropdown .dropdown-menu {
  border: none;
}
</style>
