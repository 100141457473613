<template>
  <div cols="12">
    <carousel
      :perPageCustom="[
        [320, 1],
        [768, 2],
      ]"
      :navigationEnabled="true"
      :paginationEnabled="false"
    >
      <slide class="p-4" v-for="(image, index) in images" :key="index">
        <router-link :to="image.route" class="nav-link">
          <div class="card border-0">
            <img
              :src="image.svg"
              class="card-img-top border border-2 border-dark rounded-3"
            />
            <div class="card-body">
              <h5 class="card-title text-center">{{ image.text }}</h5>
            </div>
          </div>
        </router-link>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  data() {
    return {
      images: [
        {
          svg:
            "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a",
          text: "Flachau -  שבועיים באוסטריה",
          route: "/single_ride_with_map/1"
        },
        {
          svg:
            "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/ride72_homepage%20(1).jpg?alt=media&token=9cb847dd-3f58-44ae-b41e-438b2db42cda",
          text: "שבועיים באוסטריה- אזור זלצבורג וטירול",
          route: "/single_ride_with_map/72"
        },
        {
          svg:
            "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/ride66_homepage.jpg?alt=media&token=4c3517ad-1912-4d9d-af50-5f7332ae121f",
          text: "וינה וזלצבורג 11 ימים",
          route: "/single_ride_with_map/66"
        },
        {
          svg:
            "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/kartina960_720.jpg?alt=media&token=ad8180aa-e8b9-448d-8252-0bc411b8c6f0",
          text: "Carinthia -  שבועיים באוסטריה",
          route: "/single_ride_with_map/61"
        },
        {
          svg:
            "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F68_12_Aug_2021_12%3A20%3A51_GMT?alt=media&token=755f7389-c1ad-473a-a9e2-d280b998a3e8",
          text: "אוסטריה - זלצבורג ווינה ב-10 ימים",
          route: "/single_ride_with_map/68"
        },
        {
          svg:
            "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F79_28_Aug_2021_07%3A07%3A16_GMT?alt=media&token=42bb5161-cae9-42a8-a143-427cfb4337a9",
          text: "אוסטריה - זלצבורג ווינה ב-9 ימים",
          route: "/single_ride_with_map/79"
        }
      ]
    };
  },
  components: {
    Carousel,
    Slide
  }
};
</script>
