<template>
  <b-modal hide-footer centered v-model="isVisible">
    <section class="text-center">
      <b-row no-gutters class="justify-content-center">
        <b-col cols="12" class="py-2">
          <div class="text-center mt-2 mb-5">
            <h3 class="font-weight-bold">ליימ תועצמאב רבחתה</h3>
          </div>
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
            <div>
              <b-form-input
                v-model="text"
                placeholder="ליימ"
                class="border-0 shadow text-right"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <b-input-group class="mt-3 shadow">
                <b-input-group-prepend>
                  <b-button
                    variant="outline-light"
                    @click.prevent="$emit('showmodal', 'resetForm')"
                  >
                    <span class="turquoise">?חכש</span>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input
                  v-model="text"
                  placeholder="אמסיס"
                  class="border-0 text-right"
                ></b-form-input>
              </b-input-group>
            </div>

            <div class="text-center mt-5">
              <b-form-invalid-feedback :state="validation">
                המסיס וא שמתשמ םשב האיגש
              </b-form-invalid-feedback>
            </div>

            <div class="text-center mt-5">
              <b-row class="justify-content-center">
                <b-col lg="9" md="9">
                  <b-btn pill block variant="warning" class="py-3"
                    >תורבחתה</b-btn
                  >
                </b-col>
              </b-row>
            </div>
            <div class="text-center mt-5">
              <b-link @click.prevent="$emit('showmodal', 'signup')"
                >ןובשח ךל ןיא?<span class="turquoise"> םשריה</span></b-link
              >
            </div>
          </b-form>
        </b-col>
      </b-row>
    </section>
  </b-modal>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      text: "",
      model: {
        email: "",
        password: "",
        rememberMe: false,
      },
      validation: false,
    };
  },
  computed: {
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    onSubmit() {
      // this will be called only after form is valid. You can do api call here to login
    },
  },
};
</script>

<style>
</style>