import config from '../../common/config';

const state = {
  center: { lat: 45.508, lng: -73.587 },
  markers: [],
  placesToSet: [],
  currentPlace: null,
  appMap: null,
  appMaps: [],
  appMapsMarkers: [],

};
const getters = {
  center: state => state.center,
  markers: state => state.markers,
  placesToSet: state => state.placesToSet,
  currentPlace: state => state.currentPlace,
  appMap: state => state.appMap,
  appMaps: state => state.appMaps,
  appMapsMarkers: state => state.appMapsMarkers,
  appMapFromMaps: state => ( index ) => state.appMaps[ index ],
  

};
const actions = {
  addMap({ getters, commit, dispatch }, kwargs ){
    // const elementId = "map_canvas"
    // const appMaps = getters.appMaps;
    // state.appMaps.length
    var myOptions = {
      zoom: 8,
      center: {lat:0, lng:0},
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    var map = new google.maps.Map(document.getElementById( kwargs.elementId ), myOptions);
    var i;
    var markers = [];
    for( i=0; i < kwargs.points.length; i++ ){
      const point = { latPoint: kwargs.points[i].lat,
                      lngPoint: kwargs.points[i].lng};
      dispatch( 'addMarkerByLatAndLng', {
                  map:map,
                  rideMarkers: markers,
                  point:point
                });
    }
    commit( 'setAddAppMaps', { map: map, markers: markers } );
    dispatch( 'fitBoundesToMarkers', {
      appMap:map,
      markers: markers,
    });

  },
  addMarkerByLatAndLngToGlobalMap: ({dispatch, getters }, point) => {
    const appMap = getters.appMap;
    // const appMap = getters.appMapFromMaps(0).map;
    const rideMarkers = getters.markers;
    dispatch( 'addMarkerByLatAndLng', {
      map:appMap,
      rideMarkers: rideMarkers,
      point:point
     });
    },

  removeAllMarkersToGlobalMAp: ({dispatch, getters}) =>{
    const markers = getters.markers;
    dispatch( 'removeAllMarkers', markers );
  },

  fitBoundesToMarkersAtGlobalMap: ({dispatch, getters }) => {
    const appMap = getters.appMap;
    const markers = getters.markers;
    dispatch( 'fitBoundesToMarkers', {
      appMap:appMap,
      markers: markers
     });

  },
  addMarker: ({state, commit }) => {
    if (state.currentPlace) {
      const marker = {
        lat: state.currentPlace.geometry.location.lat(),
        lng: state.currentPlace.geometry.location.lng()
      };
      commit( 'addMarker', marker );
      commit( 'addPlace', state.currentPlace );
      commit( 'setCenter', marker);
      commit( 'setCurrentPlace', null);
    }
  },
  async geolocate({commit}) {
    const position = await navigator.geolocation.getCurrentPosition()
    const center = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };
    commit('center',center);
  },
  setOneMarker({ dispatch }, point ) {
    dispatch('removeAllMarkersToGlobalMAp');
    dispatch( 'addMarkerByLatAndLngToGlobalMap', point );
    dispatch( 'fitBoundesToMarkersAtGlobalMap');
  },
}
const mutations = {
  setCurrentPlace( state, place ) {
    state.currentPlace = place;
  },
  setAppMap( state, appMap ) {
    state.appMap = appMap;
  },
  setAddAppMaps( state, appMap ) {
    state.appMaps.push( { appMap:appMap, markers:[]} );
  },
  setClearAppMaps( state ) {
    while ( state.appMaps.length > 0) {
      state.appMaps.pop();
    }
    // we should consider to remove this one
    while ( state.appMapsMarkers.length > 0) {
      state.appMapsMarkers.pop();
    }
  },
  // we should consider to remove this one
  setAddAppMapsMarkers( state, marker ) {
    state.appMapsMarkers.push( marker );
  },
  addMarker( state, marker ) {
    state.markers.push( marker );
  },
  addPlace( state, place ) {
    state.placesToSet.push( { place } );
  },
  setCenter( state, center ) {
    state.center =  center;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
