<template>
  <div
    class="text-right"
    style="font-size: 14px; letter-spacing: 0.045em; line-height: 18px"
  >
    <span v-if="visible === false">
      <span style="white-space: pre-wrap">{{ text.slice(0, 200) }}</span>
      <span v-if="text > text.slice(0, 200)" style="white-space: pre-wrap"></span>
        ...</span
      >
      <a
        v-if="text > text.slice(0, 200)"
        class="nav-link"
        :class="visible ? 'd-none' : 'd-block'"
        href
        @click.prevent="visible = !visible"
        data-bs-toggle="collapse"
        :data-bs-target="`#collapse${uniqueId}`"
        aria-expanded="false"
        aria-controls="collapseWidthExample"
        >הצג עוד
      </a></span
    >

    <span class="collapse collapse-horizontal" :id="`collapse${uniqueId}`">
      {{ text }}
      <a
        class="nav-link"
        href
        data-bs-toggle="collapse"
        :data-bs-target="`#collapse${uniqueId}`"
        aria-expanded="false"
        aria-controls="collapseWidthExample"
        @click.prevent="visible = !visible"
        >הצג פחות
      </a>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    text: "",
    uniqueId: null
  },
  data() {
    return {
      visible: false
    };
  }
};
</script>