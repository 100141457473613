<template>
  <div>
    <section class="d-flex align-items-center mt-5 py-5">
      <b-container>
        <b-row>
          <b-col cols="12" class="my-3">
            <b-button 
              pill 
              variant="outline-dark"
              class="px-5 py-3"
              @click="ReturnToSingleRide()"
              >חזרה לטיול</b-button
            >
          </b-col>
          <b-col v-if="haveFormFromBaseRide" cols="12" class="my-3">
            <ins
              class="bookingaff"
              data-aid="2186348"
              data-target_aid="2186348"
              data-prod="map"
              data-width="100%"
              data-height="390"
              data-lang="he"
              data-dest_id="0"
              data-dest_type="landmark"
              :data-latitude=accommodationFormFromBaseRide.latitude
              :data-longitude=accommodationFormFromBaseRide.longtitude
              data-mwhsb="0"
            >
              <!-- Anything inside will go away once widget is loaded. -->
              <a href="//www.booking.com?aid=2186348">Booking.com</a>
            </ins>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <Footer />
  </div>
</template>

<script>


import Datepicker from 'vuejs-datepicker';


import { mapActions, mapGetters } from 'vuex';
import Footer from "@/components/Footer.vue";
import firebase from 'firebase'


export default {
  name: 'BookingDotCom',
  components: {
    Footer,
    Datepicker,

  },
  data() {
    return {
      uploadValue: 0,
      value: "",
      text: "sdfsdfasdfsdfs",
      lat: 48.2220046,
      lng: 16.3738,
      };
  },
  computed: {...mapGetters([  
                              'accommodationAddress',
                              'accommodationLat',
                              'accommodationLng',
                              'accommodationImage',
                              'isAccommodationImage',
                              'accommodationCheckIn',
                              'accommodationCheckOut',
                              'accommodationFormFromBaseRide',
                              'accommodationFormFromBaseRideCheckinFormatedToBoking',
                              'accommodationFormFromBaseRideCheckoutFormatedToBoking',
                              'haveFormFromBaseRide',
                              ]),
    date:{
      get () {
        return this.accommodationCheckIn + ' to ' + this.accommodationCheckOut;

      },
      set (value) {
        this.$store.commit('setAccommodationCheckIn', value.split(' to ')[0])
        this.$store.commit('setAccommodationCheckOut', value.split(' to ')[1])
      }
    },
  },
  methods: 
  {
    ...mapActions(['register',
                  'authenticate',
                  'validateAndupdateAppToken',
                  'createAccommodation',
                  'updateTheAccommodation',
                  'deleteTheAccommodation',
                  'uploadAccommodationIfBooked',
                  'ReturnToSingleRide',]),
    aWeekFromDoday () {
      return '15-09-2021'
    },

    aWeekFromDodayPlusOneDay () {
      return '16-09-2021'
    },
    handleUpdateserchpoint (place) {
      var googleContryAndCity = ''
      if( place.address_components.filter(ac=>~ac.types.indexOf('country')).length > 0 )
        googleContryAndCity = place.address_components.filter(ac=>~ac.types.indexOf('country'))[0].long_name + ", "
      if( place.address_components.filter(ac=>~ac.types.indexOf('locality')).length > 0 ){
        googleContryAndCity += place.address_components.filter(ac=>~ac.types.indexOf('locality'))[0].long_name
      }
      this.updateAccommodationGoogleContryAndCity( googleContryAndCity );
      this.updateAccommodationLat( place.geometry.location.lat());
      this.updateAccommodationLng( place.geometry.location.lng());
      // this.updateAccommodationAddress( place.formatted_address );
    },
 },
  created() {
    // this.validateAndupdateAppToken();
    // We should upload the accommodation infirmation if this is for editing
    this.uploadAccommodationIfBooked(); 
   },
  mounted() {
    (function (d, sc, u) {
      var s = d.createElement(sc),
        p = d.getElementsByTagName(sc)[0];
      s.type = "text/javascript";
      s.async = true;
      s.src = u + "?v=" + +new Date();
      p.parentNode.insertBefore(s, p);
    })(
      document,
      "script",
      "//aff.bstatic.com/static/affiliate_base/js/flexiproduct.js"
    );
    localStorage.setItem('workAroundToFixBookingMap_reloadePageInOrdertoCleanTheScript', 'true');
  },
};
</script>

<style>
</style>