import axios from 'axios';
import config from '../common/config';

export default {
  fetchRide(token, lowedRideId) {
    return axios.get(`${config.config.ROOT_URL}/ride_attractions_api/${lowedRideId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },

  uploadride(ride, token) {
    const promises = Array.from(ride).map(ride => {
      const formData = new FormData();
      formData.append('ride', ride);

      return axios.post(`${config.config.ROOT_URL}/3/image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    });

    return Promise.all(promises);
  },
};
