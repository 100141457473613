<template>
  <div class="container">
    <header>
      <div class="row bg-warning d-md-none">
        <div class="col-4 py-2 d-flex justify-content-start align-items-center">
          <a
            class="btn nav-btn"
            @click.prevent
            data-bs-toggle="modal"
            data-bs-target="#menuModal"
          >
            <i class="bi-list fs-1"></i>
          </a>
        </div>
        <div
          class="col-4 py-2 d-flex justify-content-center align-items-center"
        >
          <img
            src="https://tripplanner.co.il/img/tripplanner-dark.c25b3d1f.svg"
            alt="Workflow"
            class="w-100"
          />
        </div>
        <div class="col-4 py-2 d-flex justify-content-end align-items-center">
          <a class="btn nav-btn" @click.prevent>
            <i class="bi-search fs-1"></i>
          </a>
        </div>
      </div>
      <div
        class="
          row
          justify-content-between
          align-items-center
          d-none d-md-flex
          py-3
        "
      >
        <div class="col-auto col-sm-auto pt-1">
          <img
            src="https://tripplanner.co.il/img/tripplanner-dark.c25b3d1f.svg"
            alt="Workflow"
          />
        </div>

        <div class="col-10 col-sm-6">
          <nav class="nav d-flex justify-content-between">
            <a
              v-for="item in navItems"
              :key="item.name"
              class="p-2 fw-bold nav-link text-black"
              :class="item.isButton ? 'btn btn-sm btn-warning' : ''"
              :href="item.href"
              :data-bs-toggle="item.isButton ? 'modal' : ''"
              :data-bs-target="item.isButton ? '#loginModal' : ''"
            >
              {{ item.name }}
            </a>
          </nav>
        </div>
      </div>
      <Modal :modalDialogCentered="false">
        <nav class="nav nav-pills flex-column">
          <div class="nav-item px-5">
            <a
              class="nav-link mb-1 fw-bold fs-5"
              :class="
                item.type === 'button'
                  ? 'border border-3 border-warning d-inline-block text-white rounded-3'
                  : item.type === 'link'
                  ? 'text-center text-secondary'
                  : 'text-white'
              "
              :href="item.href"
              v-for="item in mobileNavItems"
              :key="item.name"
            >
              {{ item.name }}
            </a>
          </div>
        </nav>
      </Modal>
      <!-- login Modal -->
      <Modal :modalDialogCentered="true">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="row">
            <div class="col-12">
              <h3 class="fw-bold text-center">התחברות</h3>
            </div>
            <div class="col-12 mb-3">
              <input
                type="email"
                class="form-control form-control-lg text-start"
                id="email"
                placeholder="מייל"
              />
            </div>
            <div class="col-12">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control form-control-lg text-start"
                  placeholder="סיסמא"
                />
                <button type="submit" class="btn btn-secondary btn-lg rounded">
                  לא זוכרים?
                </button>
              </div>
              <div class="mb-3">
                <h3 class="fw-bold text-center">או</h3>
              </div>
              <div class="mb-3">
                <button
                  class="
                    w-100
                    btn btn-lg
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                  type="submit"
                  style="background-color: #ff5a19"
                >
                  <span class="me-5 fs-1 text-white fw-bold"
                    >התחברות באמעות
                  </span>
                  <span class="position-relative text-white">
                    <i class="bi-google fs-1"></i>
                    <i
                      class="bi-plus fs-3 position-absolute"
                      style="bottom: 35%; left: 70%"
                    ></i>
                  </span>
                </button>
              </div>
              <div class="mb-3 text-center">
                <a href="" class="" style="font-size: 0.9rem"
                  >על ידי המשך התהליך אני מסכים לתנאי השימוש ולמדיניות הפרטיות
                  של האתר</a
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-warning btn-lg d-block mx-auto"
                  type="submit"
                >
                  התחברות
                </button>
              </div>
              <div class="mb-3 text-center">
                <a href="" class=""> אין לכם חשבון? הרשמה </a>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </header>
  </div>
</template>
<script>
import Modal from "./Modal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AppHeader",
  data() {
    return {
      navItems: [
        { name: "עמוד הבית", href: "#", isButton: false },
        { name: "אודות", href: "#", isButton: false },
        { name: "טיולים", href: "#", isButton: false },
        { name: "התחברות", href: "#", isButton: true }
      ],
      mobileNavItems: [
        { name: "התחברות", href: "#", type: "button", align: "end" },
        { name: "עמוד הבית", href: "#", type: "link", align: "end" },
        { name: "אודות", href: "#", type: "link", align: "end" },
        { name: "טיולים שלי", href: "#", type: "link", align: "end" },
        { name: "טיולים ששיתפתי", href: "#", type: "link", align: "end" },
        { name: "טיולים ששיתפו", href: "#", type: "link", align: "end" },
        { name: "שיתוף טיול חדש", href: "#", type: "link", align: "end" },
        { name: "עזרה", href: "#", type: "text", align: "end" },
        { name: "תקנון", href: "#", type: "link", align: "end" },
        { name: "יצירת קשר", href: "#", type: "link", align: "end" }
      ]
    };
  },
  components: { Modal },
  computed: mapGetters(["isAppLoggedIn", "userName", "email"]),
  methods: mapActions([
    "login",
    "appLogOut",
    "validateAndupdateAppToken",
    "openLoginForm"
  ]),
  created() {
    this.validateAndupdateAppToken();
  }
};
</script>
