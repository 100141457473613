<template>
  <div class="row g-0 jumbotron">
    <div class="col-12">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-6 text-right mb-5">
            <template>
              <!-- <b-breadcrumb
                  :items="items"
                  class="mt-5"
                  style="background-color: transparent; direction: rtl"
                ></b-breadcrumb> -->
            </template>
            <div v-if="allRide.ride">
              <div dir="rtl">
                <h2 class="h2">{{ allRide.ride.title }}</h2>
                <span style="white-space: pre-wrap">{{
                  allRide.ride.text
                }}</span>
              </div>
              <button
                v-if="compareToUserId(allRide.ride.authorId)"
                class="btn btn-warning rounded-pill px-5 py-3 mt-4"
                @click="editRide(allRide.ride.id)"
              >
                עריכת פרטי הטיול
              </button>
              <button
                v-else
                class="btn btn-warning rounded-pill px-5 py-3 mt-4"
                @click="addRideToUser(allRide.ride.id)"
              >
                הוספת טיול זה לרשימת הטיולים שלי ועריכתו
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SingleRideHeader",
  data() {
    return {
      items: [
        {
          text: "תיבה דומע",
          href: "#"
        },
        {
          text: "םידלי 4 םע םוי 14 הירטסוא",
          active: true
        }
      ]
    };
  },
  computed: mapGetters([
    "allRide",
    "isAppLoggedIn",
    "userId",
    "compareToUserId"
  ]),
  methods: {
    ...mapActions([
      "addRideToUser",
      "serchAttractionToRide",
      "removeDayFromRide",
      "addNewDayToRide",
      "removeAttractionFromRide",
      "validateAndupdateAppToken",
      "openLoginForm",
      "showModal",
      "openRideInformationPage"
    ]),
    removeAttractionFromRideInternal(rideId) {
      if (this.isAppLoggedIn) {
        this.removeAttractionFromRide(rideId);
      } else {
        this.openLoginForm();
      }
    },
    editRide(rideId) {
      this.openRideInformationPage(rideId);
    }
  },
  created() {
    this.validateAndupdateAppToken();
  }
};
</script>

<style scoped>
.jumbotron {
  background: url("../assets/images/header.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
</style>
