<template>
<b-row>
    <b-col v-for="(image, index) in images" :key="index" lg="3" md="6" cols="6" :order-lg="index+1" :order-md="orderMD(index)" :order="orderMD(index)" class="my-2">
        <b-card no-body class="text-center border-0" bg-variant="transparent">
            <b-card-img :src="require('@/assets/images/icons/' + image.svg)" img-alt="Card Image" style="width:60px;margin-left:auto; margin-right:auto;"></b-card-img>
            <b-card-text class="mt-4">
                <strong>
                    {{image.title}}
                </strong>
            </b-card-text>
            <b-card-text class="mt-3">
                {{image.subtitle}}
            </b-card-text>
            <!-- <b-card-subtitle>{{image.subtitle}}</b-card-subtitle> -->
        </b-card>
    </b-col>
</b-row>
</template>

<script>
export default {
    data() {
        return {
            images: [{
                    svg: 'icons8-new-ticket-100 1.svg',
                    title: 'הזמנה קלה ונוחה של מלונות',
                    subtitle: 'הזמנת מלונות לכל הטיול במקום אחד'
                },
                {
                    svg: 'icons8-compose-100 1.svg',
                    title: 'עריכה קלה של הטיול',
                    subtitle: 'ניתן להוסיף או להוריד אטרקציות לאחר בחירת טיול '
                },
                {
                    svg: 'icons8-profiles-100 1.svg',
                    title: 'כל הטיולים בוצעו ע"י מטיילים אחרים',
                    subtitle: 'אין כמו המלצות ממטילים שכבר עשו את המסלול, כל המסלולים בוצעו ע"י מטילים  '
                },
                {
                    svg: 'icons8-registry-editor-100 1.svg',
                    title: 'מאגר גדול של אטרקציות וטיולים',
                    subtitle: 'בסיס גדול של טיולים ושל אטרקציות שאנשים עשו ובוחרים לשתף'
                }
            ]
        }
    },
    methods: {
        orderMD(index) {
            let md = 0
            switch (index) {
                case 0:
                case 1:
                    md = index + 3
                    break;
                case 2:
                case 3:
                    md = index - 2
                    break;
            }
            return md;
        }
    },
}
</script>
