<template>
  <div>
    <section
      class="d-flex align-items-center"
      style="min-height: calc(100vh - 150px)"
    >
      <b-container class="my-5">
        <b-row>
          <b-col cols="12">
            <b-card no-body class="border-0 shadow py-3">
              <h3 v-if="isNewAttractionEditMode" class="font-weight-bold text-center my-5">
                עריכת הפעילות
              </h3>
              <h3 v-else class="font-weight-bold text-center my-5">
                הכנסת פעילות נוספת
              </h3>
              <b-row>
                <b-col cols="11" lg="8" md="10" class="mx-auto">
                  <b-row>
                    <b-col cols="4">
                      <b-row class="h-100">
                        <b-col cols="12" class="d-flex d-md-none mb-5">
                          <b-avatar square size="100%" variant="light">
                            <b-icon-camera-fill
                              class="turquoise"
                              font-scale="2"
                            ></b-icon-camera-fill>
                          </b-avatar>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="8" >
                      <div  dir="rtl" >
                        <b-row>
                          <b-col>
                            <div class="mb-3">
                              <b-form-input
                                :value="newAttractionTitle"
                                @input="updateTitle"
                                placeholder="שם הפעילות"
                                class="border-0 shadow text-right"
                                size="lg"
                              ></b-form-input>
                            </div>
                            <div class="my-3">
                              <b-form-input
                                :value="newAttractionAddress"
                                @input="updateAddress"
                                placeholder="כתובת"
                                class="border-0 shadow text-right"
                                size="lg"
                              ></b-form-input>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <div class="my-3">
                              <b-form-input
                                :value="newAttractionLink"
                                @input="updateLink"
                                placeholder="לינק לאתר"
                                class="border-0 shadow text-right"
                                size="lg"
                              ></b-form-input>
                            </div>
                          </b-col>
                          <b-col cols="12">
                            <div class="mt-3">
                              <textarea-autosize
                                class="border-0 shadow form-control text-right"
                                placeholder="טקסט חופשי על הפעילות"
                                ref="myTextarea"
                                v-model="attractionText"
                                :min-height="151"
                              />
                            </div>
                          </b-col>
<!-- we have aproblem that if we have an ' inside of the text, the part will shift to one of the sides -->
                          <div v-if="newAttractionOpeningHours">  
                            <div 
                              v-for="(oneDayOpenHours, index ) in newAttractionOpeningHours"
                              :key="index">
                              <h6>
                                {{oneDayOpenHours}}
                              </h6>
                            </div>
                          </div>
                          <div  v-for="(review, index ) in newAttractionReview"
                                :key="index">
                            <p>
                              {{review.author_name}}
                              
                            </p>
                            <p>
                              {{review.rating}}
                            </p>
                            <p>
                              {{review.text}}
                            </p>
                            <p>
                              ------------
                            </p>
                          </div>
                          <b-col cols="12">
                           <img 
                              v-if="isNewAttractionImage"
                              class="preview"
                              height="200"
                              width="200"
                              :src="newAttractionImage">
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" class="text-center my-3">
                  <b-btn
                    pill
                    variant="warning"
                    class="py-3 px-5 text-white"
                    @click="ReturnToSingleRide()"
                    >חזרה לטיול</b-btn>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <Footer />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import Footer from "@/components/Footer.vue";
import firebase from 'firebase'

export default {
  name: 'Attraction',
  components: {
    Footer,

  },
  computed: {
    ...mapGetters([ 'allRide',
                    'newAttractionTitle',
                    'newAttractionAddress',
                    'newAttractionText',
                    'newAttractionLink',
                    'newAttractionLat',
                    'newAttractionLng',
                    'newAttractionImage',
                    'isNewAttractionImage',
                    'newAttractionpreviewImage',
                    'isNewAttractionEditMode',
                    'newAttractionOpeningHours',
                    'newAttractionReview' ]),
    attractionText:{
        get () {
          return this.newAttractionText
        },
        set (value) {
          this.$store.commit('setNewAttractionText', value)
        }
      },
  },
  data() {
    return {
      value: "",
      lastChosenElement : null,
      uploadValue : 0,

    };
  },
  methods: 
  {
    ...mapActions([ 'uploadAttractionFromServer',
                    'setOneMarker',
                    'ReturnToSingleRide']),
    uploadImage(e){
      let files = e.target.files || e.dataTransfer.files;
      let imageData = files[0];
      var today=new Date();
      let myTime = today.toUTCString().split(', ')[ 1 ].split(' ').join('_')
      const storageRef = firebase.storage().ref( 'attractions/' + this.allRide.ride.id.toString() + '_' + myTime ).put(imageData);
      
      storageRef.on(`state_changed`,snapshot=>{
      this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
          storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              const img1 = url;
              this.$store.commit('setNewAttractionImage', img1)
            });
          }      
        );
    },
    isUplowding(){
      return this.uploadValue > 0
    },
    handleUpdateserchpoint (place) {
      this.updateLat( place.geometry.location.lat());
      this.updateLng( place.geometry.location.lng());
    },
    handleautoCompliteForm (place) {
      this.handleUpdateserchpoint(place)
      this.updateTitle( place.name )
      this.updateAddress( place.formatted_address )
      this.updateLink( place.website )
      this.updateRating( place.rating )
      this.updatePlaceId( place.place_id )
      if ( place.geometry ){
        this.setOneMarker( {  latPoint: place.geometry.location.lat(),
                              lngPoint: place.geometry.location.lng(),
                              name:"the new point"} )
        this.$emit("serchPointChange", place);
      }
    },
    updateTitle ( value ) {
      this.$store.commit('setNewAttractionTitle', value)
    },
    updateAddress ( value ) {
      this.$store.commit('setNewAttractionAddress', value)
    },
    updateText ( value ) {
      this.$store.commit('setNewAttractionText', value)
    },
    updateLink ( value ) {
      this.$store.commit('setNewAttractionLink', value)
    },
    updateRating ( rate ) {
      this.$store.commit('setNewAttractionRate', rate)
    },
    updateLat (lat) {
      this.$store.commit('setNewAttractionLat', lat)
    },
    updateLng (lng) {
      this.$store.commit('setNewAttractionLng', lng)
    },
    updateImage ( value ) {
      this.$store.commit('setNewAttractionImage', value)
    },
    updatePlaceId ( value ) {
      this.$store.commit('setNewAttractionPlaceId', value)
    },
  },
};
</script>

<style  scoped >
.brightness {
    background-color: white;
    display: inline-block;

}
.brightness img:hover {
    opacity: .5;
}
img{margin:10px;}
.selected{
  box-shadow:0px 12px 22px 1px #333; border:solid 3px yellow;
}
</style>