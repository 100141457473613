<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="6" md="3" v-for="(image, index) in images" :key="index">
          <b-img
            fluid
            :src="require('@/assets/images/icons/' + image.svg)"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  data() {
    return {
      images: [
        {
          svg: "Booking_com_tcm583-4430305 2.svg",
        },
        {
          svg: "waze.svg",
        },
        {
          svg: "google-1-1.svg",
        },
        {
          svg: "Skyscanner-new-Logo 2.svg",
        },
      ],
    };
  },
  components: {
    Carousel,
    Slide,
  },
};
</script>
