import api from '../../api/globalRides';

const state = {
  globalRides: [],
  globalRidesFilters: {'status': 'noFilters'},
};

const getters = {
  globalRides: state => state.globalRides,
  globalRidesFilters: state => state.globalRidesFilters,
};

const actions = {
  async fetchGlobalRides({ dispatch, state, commit }) {
    const globalRidesFilters = state.globalRidesFilters
    const response = await api.fetchGlobalRides( globalRidesFilters );
    commit('setGlobalRides', response.data.rides);
 
  },
  postFetchGlobalRides({ dispatch, getters }) {

    const rides = getters.globalRides;
    for(var i=0; i < rides.length; i++ ){
      dispatch( 'addMap', { elementId:"map_canvas_"+i,
                            points: rides[i].highLevelRideLocations });
    }
  },
};

const mutations = {
  setGlobalRides: (state, rides) => {
    state.globalRides = rides;
  },
  setGlobalRidesFilters: (state, filters) => {
    state.globalRidesFilters = filters;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
