<template>
  <div class="py-5">
    <div class="container">
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3">
        <div
          class="col"
          :class="`order-${sponsor.order} order-md-${sponsor.orderMd}`"
          v-for="(sponsor, index) in sponsors"
          :key="index"
        >
          <img
            class="img-fluid"
            style="width: 18rem"
            :src="require(`@/assets/images/icons/${sponsor.icon}.png`)"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// const loadIcon = icon => {
//   return require(`../assets/images/icons/${icon}.png`);
//   //  require("../assets/images/header.png");
//   // return import(`../components/svg/${icon}.vue`);
// };
export default {
  data() {
    return {
      sponsors: {
        skyscanner: {
          icon: "skyscanner_logo",
          order: 3,
          orderMd: 1
        },
        google: {
          icon: "google_logo",
          order: 4,
          orderMd: 2
        },
        waze: {
          icon: "waze_logo",
          order: 1,
          orderMd: 3
        },
        booking: {
          icon: "booking_com_logo",
          order: 2,
          orderMd: 4
        }
      }
    };
  }
};
</script>
