import config from '../../common/config';
import api from '../../api/singleride';
import editApi from '../../api/editRide';

import { router } from '../../main';

const state = {
  ride: {},
  rideId: null,
  lowedRideId: null,
  nevigateMode: false,
};

const getters = {
  allRide: state => state.ride,
  rideId: state => state.rideId,
  lowedRideId: state => state.lowedRideId,
  nevigateMode: state => state.nevigateMode,
};

const actions = {
  async fetchRide({ rootState, state, commit, dispatch }) {
    const { apptoken } = rootState.appauth;
    const lowedRideId = state.lowedRideId;
    dispatch( 'showModal', "loadingIcon" );
    const response = await api.fetchRide(apptoken, lowedRideId);
    dispatch( 'showModal', "" );
    commit('setRide', response.data);
    await dispatch('rideUpdateMarkerByScrollerLocation');

    dispatch( 'addMap', { elementId:"map_canvas",
                          points: response.data.highLevelRideLocations });
  },
  updateMainRideScroleLocation({dispatch ,state, commit, getters }){
    localStorage.setItem('workAroundToSetTheScroleLocationAtTheMAinPage', window.top.scrollY);
  },
  async uploadRide({ rootState }, ride) {// this one is not in use!!!!!!!!!
    // Get the access token
    const { apptoken } = rootState.appauth;

    // Call our API module to do the upload
    await api.uploadRide(ride, apptoken);

    // Redirect our user to ImageList component
    router.push('/');
  },
  ReturnToSingleRide({ rootState }) {
    const rideId = rootState.ride.rideId;
    router.push('/single_ride_with_map/' + rideId );
  },
  async removeDayFromRide({ rootState, dispatch }, dayIndex ) {
    const { apptoken } = rootState.appauth;
    const rideId = rootState.ride.rideId;
    const response = await editApi.removeDayFromRide( apptoken, rideId, dayIndex );
    if( response.data.ServerMassageCode != "secsses" ){
      dispatch( 'showModalWithText',
                { errorNumber : response.data.ServerMassageCode,
                } );
    }
    await dispatch('fetchRide');
  },
  async addNewDayToRide({ rootState, dispatch }, dayIndex ) {
    const rideId = rootState.ride.rideId;
    const { apptoken } = rootState.appauth;
    const response = await editApi.addNewDayToRide( apptoken, rideId, dayIndex);
    if( response.data.ServerMassageCode != "secsses" ){
      dispatch( 'showModalWithText',
                { errorNumber : response.data.ServerMassageCode,
                } );
    }

    await dispatch('fetchRide');
  },
  async getTheClosestAttractionStartFromGoingDown( { rootState, commit },dayIndex ){
    const ride = rootState.ride;
    while( (ride.ride.attractionsByDay[dayIndex].daily_attractions.length == 0) && ( dayIndex > 0 )){
      dayIndex = dayIndex - 1;
    }
    // serche after the date if not exist
    if( ride.ride.attractionsByDay[dayIndex].daily_attractions.length == 0 ){
      while(  (ride.ride.attractionsByDay[dayIndex].daily_attractions.length == 0) &&
              ( ( ride.ride.attractionsByDay.length - 1 ) > dayIndex ) ) {
        dayIndex = dayIndex + 1;
      }
    }
    const dylyactivity = ride.ride.attractionsByDay[dayIndex]
    var i;
    var lastLng = 0;
    var lastLat = 0;
    for( i = 0; i < dylyactivity.daily_attractions.length; i++ ){
      if( dylyactivity.daily_attractions[i].latitude ){
        lastLat = dylyactivity.daily_attractions[i].latitude;
        lastLng = dylyactivity.daily_attractions[i].longtitude;
      }
    }
    return{ 'lat': lastLat, 'lng':lastLng }

  },
  async serchAttractionToRide({ rootState, commit, dispatch }, dayIndex ) {
    const ride = rootState.ride
    const originalDayIndex = dayIndex;
    const point =  await dispatch('getTheClosestAttractionStartFromGoingDown', dayIndex );
    
    var lat = point.lat;
    var lng = point.lng;
    if( ride.ride.attractionsByDay[originalDayIndex].daily_attractions.length == 0 ){
      if( ride.ride.attractionsByDay[originalDayIndex].accommodation.latitude ){//                 wehave an booking information for this morning)){
        lat = ride.ride.attractionsByDay[originalDayIndex].accommodation.latitude
        lng = ride.ride.attractionsByDay[originalDayIndex].accommodation.longtitude
      }
      else if( ride.ride.attractionsByDay[originalDayIndex].base_trip_accommodation.latitude ){//    wehave an booking information for this morning)){
        lat = ride.ride.attractionsByDay[originalDayIndex].base_trip_accommodation.latitude
        lng = ride.ride.attractionsByDay[originalDayIndex].base_trip_accommodation.longtitude
      }
    }

    var palannedDate = new Date(ride.ride.attractionsByDay[originalDayIndex].dateTime);
    palannedDate.setHours(23,59);
    commit('setSerchPlannedDate', palannedDate.toLocaleString("en-US"));
    commit('setSerchLat', lat);
    commit('setSerchLng', lng);
    commit('setSerchDayIndex', originalDayIndex);
    router.push('/serch_attractions_with_map');
  },
  async removeAttractionFromRide( { rootState, commit }, attractionId){
    const rideId = rootState.ride.rideId;
    const { apptoken } = rootState.appauth;
    await editApi.removeAttractionFromRide( apptoken, rideId, attractionId );
    const response = await api.fetchRide(apptoken, rideId);
    commit('setRide', response.data);
  },
};

const mutations = {
  setNevigateMode: (state, isNevigateMode ) => {
    state.nevigateMode = isNevigateMode
  },
  setRide: (state, ride) => {
    state.ride = ride;
    if( ride.ride ){
      state.rideId = ride.ride.id;
    }
    console.log( ride );
  },
  setLowedRideId: (state, lowedRideId) => {
    state.lowedRideId = lowedRideId;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
