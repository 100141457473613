<template>
<b-row>
    <b-col cols="12">
        <carousel :perPageCustom="[[768, 3], [1024, 4]]" :navigationEnabled="true" :paginationEnabled="false">
            <slide class="p-4" v-for="(image, index) in images" :key="index">
              <router-link :to=image.route>
          <b-card
            no-body
            overlay
            :img-src=image.svg
            img-alt="Card Image"
            :key="index"
            class="text-center"
            style="position: relative"
          >
            <b-card-text
              class="bg-white"
              style="
                position: absolute;
                width: 100%;
                bottom: 50px;
                opacity: 0.7;
              "
            >
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-sm mb-0">{{ image.text }}</p>
              </b-list-group-item>
            </b-card-text>
          </b-card>
              </router-link>
            </slide>
        </carousel>
    </b-col>
</b-row>
</template>

<script>
import {
    Carousel,
    Slide
} from 'vue-carousel';
export default {
    data() {
        return {
            images: [{
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
                    text: 'Flachau -  שבועיים באוסטריה',
                    route: '/single_ride_with_map/1'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
                    text: 'Flachau -  שבועיים באוסטריה',
                    route: '/single_ride_with_map/1'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
                    text: 'Flachau -  שבועיים באוסטריה',
                    route: '/single_ride_with_map/1'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
                    text: 'Flachau -  שבועיים באוסטריה',
                    route: '/single_ride_with_map/1'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
                    text: 'Flachau -  שבועיים באוסטריה',
                    route: '/single_ride_with_map/1'
                },
            ]
        }
    },
    components: {
        Carousel,
        Slide
    },
}
</script>
