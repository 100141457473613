<template>
  <div class="px-4 py-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="text-center fw-bold">?איך זה עובד</h2>
        </div>
        <div
          class="col-12 col-sm-8 col-md-3 mx-auto py-3"
          :class="
            instruction.hasBorder
              ? 'border border-2 rounded-3 border-marigold'
              : ''
          "
          v-for="(instruction, index) in instructions"
          :key="index"
        >
          <div class="row">
            <div class="col-2">
              <component :is="instruction.icon"></component>
            </div>
            <div class="col-10 col-md-8 d-flex align-items-center">
              <div class="text-start">
                <div v-for="text in instruction.text" :key="text">
                  {{ text }}
                </div>
              </div>
            </div>
            <div
              class="
                col-md-2 col-12
                d-flex
                align-items-center
                justify-content-center
                text-marigold
              "
              v-if="instruction.hasArrow"
            >
              <i class="bi-arrow-left fs-3 d-none d-md-block"></i>
              <i class="bi-arrow-down d-md-none fs-2"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const loadIcon = icon => {
  return import(`../components/svg/${icon}.vue`);
};
export default {
  data() {
    return {
      instructions: [
        {
          icon: () => loadIcon("MapIcon"),
          text: ["מעוניינים לתכנן טיול?"],
          hasArrow: true,
          hasBorder: false
        },
        {
          icon: () => loadIcon("ReceiptIcon"),
          text: ["בוחרים טיול ששותף בתור נקודת התחלה"],
          hasArrow: true,
          hasBorder: false
        },
        {
          icon: () => loadIcon("ClipboardIcon"),
          text: ["עכשיו כל מה שנשאר זה להנות", "מהטיול וכמובן לשתף"],
          hasArrow: true,
          hasBorder: false
        },
        {
          icon: () => loadIcon("DestinationIcon"),
          text: ["עכשיו כל מה שנשאר זה להנות", "מהטיול וכמובן לשתף"],
          hasArrow: false,
          hasBorder: true
        }
      ]
    };
  }
};
</script>
<style scoped>
/* .img {
  width: 60px;
  margin-left: auto;
  margin-right: auto;
} */
</style>
