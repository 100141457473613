<template>
  <b-modal hide-footer centered v-model="isVisible">
    <section class="text-center">
      <b-row no-gutters class="justify-content-center">
        <b-col cols="12" class="py-2">
          <h3 class="font-weight-bold">םשריה</h3>
          <small>
            <b-link
              @click.prevent="$emit('showmodal', 'login')"
              class="text-dark"
            >
              Tripplanner <u>לש תויטרפה תוינידמלו שומישה </u>
              <u>יאנתל םיכסמ </u>ךילהתה ךשמה ידי לע
            </b-link>
          </small>
        </b-col>
        <b-col cols="8" class="py-2">
          <b-btn pill variant="outline-info" block class="py-2">
            Google <span class="text-dark">תועצמאב םשריה</span></b-btn
          >
        </b-col>
        <b-col cols="8" class="py-2">
          <b-btn pill variant="outline-info" block class="py-2">
            Facebook <span class="text-dark">תועצמאב םשריה</span></b-btn
          >
        </b-col>
        <b-col cols="8" class="py-2"> ֹוא </b-col>
        <b-col cols="8" class="py-2">
          <b-btn
            pill
            variant="outline-warning"
            block
            class="py-2"
            @click="$emit('showmodal', 'signupForm')"
          >
            ליימ תועצמאב םשריה
          </b-btn>
        </b-col>
        <b-col cols="8" class="py-2">
          <b-link @click.prevent="$emit('showmodal', 'login')">
            <span class="turquoise">תורבחתה</span> ?ןובשח ךל שי רבכ
          </b-link>
        </b-col>
      </b-row>
    </section>
  </b-modal>
</template>
<script>
export default {
  props: ["value"],
  computed: {
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>
<style>
</style>